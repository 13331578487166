<template>
  <div>
    <modal :show.sync="reportProject" headerClasses="justify-content-center" type="custom" :scrollToBottom="false" v-on:hideModal="hideModal">
      <h4 v-if="city==='gatineau'" slot="header" class="title title-up">{{ $t('reportProj.textTitle1') }}</h4>
      <h4 v-else slot="header" class="title title-up">{{ $t('reportProj.textTitle') }}</h4>
      <template slot="close-button">
        <button type="button" class="close" @click='hideModal()' data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <form class="report-form" @submit.prevent>
        <div v-if="loading" class="loading-form">
          <img src="../../../public/img/loading.gif" />
        </div>
        <div v-else class="report-project-container">
          <div class="report-project-row col-md-12 col-sm-12" style="display: flex;">
            <div v-if="features.dropDownNoticeTypeInReportProjectModalOnProjectListiing.show" class="col-md-4 col-sm-4">
              <label> {{ $t('reportProj.labelNotice') }} </label>
              <el-select
                placeholder="Avis..."
                class="select-primary select-inline"
                name="actions"
                size="large"
                v-model="report.notice">
                <el-option
                  v-for="option in noticesSelect"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-4 col-sm-4">
              <label> {{ $t('reportProj.labelPhases') }} </label>
              <el-select
                placeholder="Action..."
                class="select-primary select-inline"
                name="actions"
                size="large"
                v-model="report.phase"
                @change="changePhase">
                <el-option
                  v-for="option in options"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label">
                </el-option>
              </el-select>
              <span v-show="errorForm.phase" class="text-danger"> {{ $t('reportProj.requiredPhase') }}</span>
            </div>
            <div v-if="features.dropDownClosuresInReportProjectModalOnProjectListiing.show" class="col-md-4 col-sm-4">
              <label> {{ $t('reportProj.titleClose') }} </label>
              <el-select
                placeholder="Action..."
                class="select-primary select-inline"
                name="actions"
                size="large"
                v-model="report.close"
                @change="changeClose">
                <el-option
                  v-for="option in optionsClosing"
                  class="select-primary"
                  :value="option.value"
                  :label="option.label"
                  :key="option.label">
                </el-option>
              </el-select>
              <span v-show="errorForm.close" class="text-danger"> {{ $t('reportProj.requiredClose') }}</span>
            </div>
          </div>
          <div class="report-project-row col-md-12 col-sm-12" style="display: flex;">
            <!-- for mail -->
              <div class="col-md-6 col-sm-6">
              <label>{{ $t('reportProj.labelSend') }}</label>
              <div style="margin-top: 8px;" class="togglebutton switch-sidebar-mini">
                <span class="label-switch">{{ $t('reportProj.radioTxtOff') }} </span>
                <base-switch v-model="report.mode" @input="changeMode"></base-switch>
                <span class="label-switch label-right"> {{ $t('reportProj.radioTxtOn') }}</span>
              </div>
            </div>
            <div class="col-md-6 col-sm-6">
              <label>{{ $t('reportProj.labelInclude') }}</label>
              <div style="margin-top: 8px;" class="togglebutton switch-sidebar-mini">
                <span class="label-switch">{{ $t('reportProj.radioTxtOff') }} </span>
                <base-switch v-model="report.hour"></base-switch>
                <span class="label-switch label-right"> {{ $t('reportProj.radioTxtOn') }}</span>
              </div>
            </div>
          </div>
          <div class="row info-divider"></div>
          <div class="report-project-row col-md-12 col-sm-12" style="display: flex;">
            <div class="col-md-2 col-sm-2">
              <label class="strong-label">{{ $t('reportProj.labelIncludeMap') }}</label>
              <div style="margin-top: 8px;" class="togglebutton switch-sidebar-mini">
                <span class="label-switch">{{ $t('reportProj.radioTxtOff') }} </span>
                <base-switch v-model="report.includeMap" ></base-switch>
                <span class="label-switch label-right"> {{ $t('reportProj.radioTxtOn') }}</span>
              </div>
            </div>
            <div v-show="report.includeMap" class="col-md-4 col-sm-4">
              <label> {{ $t('reportProj.zoomLevel') }} </label>
              <el-select
                placeholder="Zoom..."
                class="select-primary select-inline"
                name="zoom"
                size="large"
                v-model="report.zoomLevel">
                <el-option
                  v-for="element in zoomLevels"
                  class="select-primary"
                  :value="element.value"
                  :label="$t(`reportProj.zoomLevel${element.value}`)"
                  :key="element.value">
                  {{ $t(`reportProj.zoomLevel${element.value}`) }}
                </el-option>
              </el-select>
            </div>
            <div v-show="report.includeMap" class="col-md-6 col-sm-6">
              <label> {{ $t('reportProj.labelMaps') }} </label>
              <el-select
                placeholder="Maps..."
                class="select-primary select-inline"
                name="maps"
                size="large"
                v-model="report.typeMap">
                <el-option
                  v-for="element in maps"
                  class="select-primary"
                  :value="element.key"
                  :label="element.label"
                  :key="element.key">
                  <img :src="element.fname" width="36px" style="margin-right: 20px;"> {{ element.label }}
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="margin-top-20 col-md-12 col-sm-12">
            <div class="col-md-12 col-sm-12">
              <label class="strong-label">{{ $t('reportProj.labelIncludeAgent') }}</label>
              <div style="margin-top: 8px;" class="togglebutton switch-sidebar-mini">
                <span class="label-switch">{{ $t('reportProj.radioTxtOff') }} </span>
                <base-switch v-model="report.includeAgent" ></base-switch>
                <span class="label-switch label-right"> {{ $t('reportProj.radioTxtOn') }}</span>
              </div>
            </div>
          </div>
          <!--
          <div class="margin-top-20 col-md-12 col-sm-12">
            <div class="col-md-12 col-sm-12">
              <label class="strong-label"> {{ $t('reportProj.titleProjectType') }} </label>
              <div style="margin-top: 8px;" class="togglebutton switch-sidebar-mini">
                <span class="label-switch">{{ $t('reportProj.switchUpdateProject') }} </span>
                <base-switch v-model="report.projectType" ></base-switch>
                <span class="label-switch label-right"> {{ $t('reportProj.switchNewProject') }}</span>
              </div>
            </div>
          </div>
          -->
          <div v-show="report.mode" class="margin-top-20 col-md-12 col-sm-12">
            <div class="col-md-12 col-sm-12">
              <label class="strong-label"> {{ $t('reportProj.titleProjectType') }} </label>
              <div class="col-md-12 col-sm-12 report-type-container">
                <base-radio name="new"    v-model="projectType" class="col-md-6 col-sm-6" @input="newSubject()">{{ $t('reportProj.switchNewProject') }}</base-radio>
                <base-radio name="update" v-model="projectType" class="col-md-6 col-sm-6" @input="newSubject()">{{ $t('reportProj.switchUpdateProject') }}</base-radio>
              </div>
            </div>
          </div>
          <div v-show="report.mode" class="row info-divider"></div>
          <div v-show="report.mode">
            <div class="report-project-row col-md-12 col-sm-12">
              <div class="col-md-12 col-sm-12">
                <label>{{ $t('reportProj.labelDest') }}</label>
                <!-- <inputtag class="form-control" v-model='report.to' :tags.sync="report.to"  :add-tag-on-blur="true" validate="email" :allowDuplicates="false"></inputtag> -->
                <div class='tag-input'>
                  <div v-if="tag" v-for='(tag, index) in report.to' :key='tag' class='tag-input__tag'>
                    {{ tag }}
                    <span @click='removeTag(index)'><b>x</b></span>
                  </div>
                  <input 
                    type='text' 
                    class='tag-input__text' 
                    @keydown.enter='addTag' 
                    @keydown.188='addTag'
                    @keydown.delete='removeLastTag'
                  />
                </div>
                <label v-show='errorForm.to' class="error">{{ $t('reportProj.textErrDest') }}</label>
              </div>
            </div>
            <div v-show="filters.mailList.show" class="report-project-row col-md-12 col-sm-12">
                <div class="col-md-12 col-sm-12">
                  <label>{{ $t('reportProj.labelMailList') }}</label>
                  <el-select  
                    multiple
                    class="select-primary"
                    name="entity"
                    size="large"
                    v-model="defaultMailListOption">
                    <el-option
                      v-for="option in filters.mailList.value"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                      <div class="emailDropdown">
                        <div class="title">{{ option.label }}</div>
                      </div>  
                    </el-option>
                  </el-select>
                </div>
            </div>
            <div class="report-project-row col-md-12 col-sm-12">
              <div class="col-md-12 col-sm-12">
                <label>{{ $t('reportProj.labelSubject') }}</label>
                <base-input
                  name="subject"
                  v-bind:placeholder="Sujet"
                  @input="change('subject')"
                  v-model="report.subject">
                </base-input>
                <label v-show='errorForm.subject' class="error">{{ $t('reportProj.textErrSubject') }}</label>
              </div>
            </div>
            <div class="report-project-row col-md-12 col-sm-12">
              <div class="col-md-12 col-sm-12">
                <label>{{ $t('reportProj.labelMsg') }}</label>
                <vue-pell-editor 
                    v-model="report.message"
                    @change="change('message')"
                    :actions="editorOptions"
                    :placeholder="editorPlaceholder"
                    :style-with-css="false"
                    :classes="editorClasses"
                    default-paragraph-separator="p"/>
              </div>
              <label v-show='errorForm.message' style="margin-left: 15px;" class="error">{{ $t('reportProj.textErrMssg') }}</label>
            </div>
            <div class="report-project-image report-project-row col-md-12 col-sm-12">
              <div class="col-md-12 col-sm-12">
                <image-upload  @change="onImageChange" v-model="report.files" select-text="Select Image" />
              </div>
            </div>
          </div>
        </div>  
      </form>
      <template slot="footer">
        <div class="report-btn-left">
          <base-button class="preview-btn" type="warning" v-on:click="handlePdfView(1)" style="margin-right: 10px;" >{{ $t('reportProj.btnOverview') }}</base-button>
        </div>
        <div class="report-btn-right">
          <!--<base-button type="danger" v-on:click="hideModal()">Annuler</base-button>-->
          <base-button v-show="!report.mode" type="info" v-on:click="handlePdfView(2)" >{{ $t('reportProj.btnDownld') }}</base-button>
          <base-button v-show="report.mode" v-on:click="sendEmail" >{{ $t('reportProj.btnSend') }}</base-button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
import filters from './../shared/filters';
import features from './../shared/features';

Vue.use(VuePellEditor);
import InputTag from 'vue-input-tag';
Vue.component('inputtag', InputTag);
import i18n from './../../i18n';

import { uuid } from 'vue-uuid';

import { Select, Option } from 'element-ui';

import { Modal, BaseSwitch, BaseRadio } from 'src/components';
import { ImageUpload } from 'src/components/index';

export default {
  name:'report-project',
  props: ['reportProject', 'reportProjectUUID', 'reportProjectInfo'],
  components: {
    Modal,
    BaseSwitch,
    BaseRadio,
    [Option.name]: Option,
    [Select.name]: Select,
    ImageUpload
  },
  watch: {
    defaultMailListOption(value){
      if(!value) return;
      let allMails = "";
      value.forEach(item => {
        let emails = item;
        if(allMails){
          emails = `,${emails}`
        }
        allMails += emails;
      })
      this.report.to = allMails.split(',');
    },
    'report.to'() {
      this.errorForm.to=false;
    },
    reportProject() {
      let _this = this;
      this.resetReportModal();
      
      this.options.push({value: '', label: _this.$i18n.t('reportProj.labelDispBlank')});     
      if (this.city!='gatineau') { 
        this.options.push({value: 'all', label: _this.$i18n.t('reportProj.labelDispAll')});
        this.phase='all';
      } else {
        this.phase='';
      }

      this.reportProjectInfo.reportPhases.forEach((e) => {
        if (this.city!='gatineau' &&  this.city!='montroyal') {
          let label = _this.$i18n.t('reportProj.labelPhase') + ' #' + (e.index+1); 
          _this.options.push({value: e.phaseId, label: label});
        } else if (e.closing.length > 0) {
          let label = _this.$i18n.t('reportProj.labelPhase') + ' #' + (e.index+1); 
          _this.options.push({value: e.phaseId, label: label});
          _this.closingByPhase[e.phaseId] = []; 
          if (this.city=='montroyal'){
              _this.closingByPhase[e.phaseId].push({value: 'all', label: _this.$i18n.t('reportProj.labelDispAll')})
            }          
          e.closing.forEach((close) => {
            let label = _this.$i18n.t('reportProj.labelClose') + ' #' + (close.index+1); 
            _this.closingByPhase[e.phaseId].push({value: close.closeId, label: label});
            
          });
        }
      });
      _this.report.ruuid=uuid.v1();
    }
  },
  data() {
    return {
      loading: false,
      closingByPhase: {},
      noticesSelect: [
        {value: '1', label: i18n.t('reportProj.notice1')}, 
        {value: '2', label: i18n.t('reportProj.notice2')}
      ],
      report: {
        hour: true,
        includeMap: true,
        includeAgent: false,
        typeMap: 'gray',
        modeMap: '0',
        phase: '',
        close: '',
        notice: '1',
        to: [],
        subject: this.$i18n.t('reportProj.new'),
        message: '',
        file: '',
        ruuid: null,
        mode: false,
        zoomLevel: 15,
      },
      projectType: 'new',
      maps: [
        { active: true,  modeMap: '0', key:'osm', label: 'OpenStreetMap', fname: 'static/img/map/osm.jpg' },
        { active: false, modeMap: '0', key:'streets', label: 'Streets', fname: 'static/img/map/streets.jpg' },
        { active: false, modeMap: '0', key:'topo', label: 'Topographic', fname: 'static/img/map/topo.jpg' },
        { active: false, modeMap: '0', key:'dark-gray', label: 'Dark Gray Canvas', fname: 'static/img/map/dark_gray_canvas.png' },
        { active: false, modeMap: '0', key:'gray', label: 'Light Gray Canvas', fname: 'static/img/map/light_gray_canvas.jpg' },
        { active: false, modeMap: '0', key:'satellite', label: 'Imagery', fname: 'static/img/map/imagery.jpg' },
        { active: false, modeMap: '0', key:'national-geographic', label: 'National Geographic', fname: 'static/img/map/nat_geo.jpg' },
        { active: false, modeMap: '0', key:'terrain', label: 'Terrain with Labels', fname: 'static/img/map/terrain_labels.jpg' },
        { active: false, modeMap: '1', key:'098132d21c014e62908d847e3b74d740', label: 'Dark Grey-Community Map of Canada', fname: 'static/img/map/dark_grey_canada.jpeg' },
        { active: false, modeMap: '1', key:'e1de1cfd7a624639b65e2cc0f10aad70', label: 'Light Grey-Community Map of Canada', fname: 'static/img/map/light_grey_canada.jpeg' },
        { active: false, modeMap: '1', key:'41fff870ffc2461ab79cd949da2ac1bc', label: 'Streets (Night)-Community Map of Canada', fname: 'static/img/map/streets_night_canada.png' },
        { active: false, modeMap: '1', key:'cba1e859423a424eb26fb73c5df5ecdf', label: 'Streets-Community Map of Canada', fname: 'static/img/map/streets_canada.png' },
        { active: false, modeMap: '1', key:'98652eb8458a464fa95feb9bd812b29a', label: 'Topographic-Community Map of Canada', fname: 'static/img/map/topographic_canada.jpeg' }
      ],
      zoomLevels:[
        { value: 11, label: i18n.t('reportProj.zoomLevel11') },
        { value: 12, label: i18n.t('reportProj.zoomLevel12') },
        { value: 13, label: i18n.t('reportProj.zoomLevel13') },
        { value: 14, label: i18n.t('reportProj.zoomLevel14') },
        { value: 15, label: i18n.t('reportProj.zoomLevel15') },
        { value: 16, label: i18n.t('reportProj.zoomLevel16') },
        { value: 17, label: i18n.t('reportProj.zoomLevel17') },
        { value: 18, label: i18n.t('reportProj.zoomLevel18') },
        { value: 19, label: i18n.t('reportProj.zoomLevel19') },
      ],
      options: [],
      optionsClosing: [],
      error: false,
      errorForm: {
        to: false,
        subject: false,
        message: false,
        phase: false,
        close: false
      },
      editorOptions: [
        'bold',
        'underline', {
            name: 'italic',
            result: () => window.pell.exec('italic')
        }, 
        'heading1',
        'heading2',
        'paragraph',
        'strikethrough',
        'olist',
        'ulist',
        'code',
        'line'
      ],
      editorPlaceholder: this.$i18n.t('reportProj.placeHoldMsg'),
      editorClasses: {
        actionbar: 'pell-actionbar custom-actionbar',
        button: 'pell-button',
        content: 'pell-content',
        selected: 'pell-button-selected'
      },
      city: '',
      defaultMailListOption:"",
      filters: [],
      features: null
    };
  },
  created() {
    let instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city = instance.city;
    this.filters = filters[this.city];
    this.features = features[this.city];
    const user = JSON.parse(window.localStorage.getItem('user'));
    if(this.filters.mailList.show){
      this.defaultMailListOption = user.lang === 'fr' ? "Sélectionnez la liste de diffusion": "Select Mail List";
    }
    if( this.city === 'sjsr'){
      this.maps.push({ active: false, modeMap: '2', key:'92c9dbcdd3ba4b95a07c2116c7b1cb09', label: 'SJSR Custom basemap', fname: 'static/img/map/sjsr.png' });
    }
  },
  methods: {
    changeMode() {
      this.report.message = '';
      this.report.to = [];
      this.report.file=null;
      //this.options=[];
      if (document.querySelector('div.report-project-image button.btn.btn-round.btn-danger')) {
        document.querySelector('div.report-project-image button.btn.btn-round.btn-danger').click(); 
      }
    },
    change(type) {
      this.errorForm[type]=false;
    },
    resetReportModal() {
      this.report = {
        hour: true,
        includeMap: true,
        includeAgent: false,
        phase: '',
        close: '',
        notice: '1',
        typeMap: 'gray',
        modeMap: '0',
        to: [],
        subject: this.$i18n.t('reportProj.new'),
        message: '',
        file: null,
        ruuid: null,
        mode: false,
        zoomLevel: 18,
      };
      this.projectType='new';
      let user=JSON.parse(window.localStorage.getItem('user'));
      if (user && user.appsettings && user.appsettings.map && user.appsettings.map.typeMap) {
        this.report.typeMap=user.appsettings.map.typeMap;
        this.report.modeMap=user.appsettings.map.modeMap;
      }
      this.options=[];
      this.optionsClosing=[];
      if (document.querySelector('div.report-project-image button.btn.btn-round.btn-danger')) {
        document.querySelector('div.report-project-image button.btn.btn-round.btn-danger').click(); 
      }
    },
    onImageChange(file) {
       this.report.file=file;
    },
    hideModal(ruuid) {
      this.defaultMailListOption = "";
      this.$emit('hideReportProject');
    },
    getHeaders() {
      return {
        'Filesize': (this.report.file && this.report.file.size) || 0
      };
    },
    checkForm() {
      if (!this.report.subject) {
        this.errorForm.subject=true;
      }
      if (!this.report.message) {
        this.errorForm.message=true;
      }
      if (this.report.to.length==0) {
        this.errorForm.to=true;
      }
      // if (this.city=='gatineau' && this.report.phase=='') {
      //   this.errorForm.phase=true;
      //   this.error=true;
      // }
      if ((this.city=='gatineau' || this.city=='montroyal') && this.report.phase!='' && this.report.close=='') {
        if(this.report.phase=='all') {
          this.errorForm.close=false;
          error=false;
        }else{ 
          this.errorForm.close=true;
          error=true;
        }
      }
      if (this.errorForm.subject || this.errorForm.message || this.errorForm.to) {
        this.error=true;
      }
    },
    handlePdfView(viewType){
      let modeMap = "0";
      const selectedMap = this.maps.find(map => map.key === this.report.typeMap);
      if (selectedMap && selectedMap.modeMap) {
        modeMap = selectedMap.modeMap;
      }
      let params = {
        'uuid': this.reportProjectUUID,
        'district': this.reportProjectInfo.reportDistrict,
        'workType': this.reportProjectInfo.reportWorkType,
        'hour': this.report.hour,
        'includeMap': this.report.includeMap,
        'includeAgent': this.report.includeAgent,
        'typeMap': this.report.typeMap,
        'modeMap': modeMap,
        'phase': this.report.phase,
        'close': this.report.close,
        'notice': this.report.notice,
        'lang': this.$root.$i18n.locale,
        'ruuid': this.report.ruuid,
        'zoom' : this.report.zoomLevel,
      };
      let error=false;
      // if (this.city=='gatineau' && this.report.phase=='') {
      //   this.errorForm.phase=true;
      //   this.errorForm.close=true;
      //   error=true;
      // }
      if ((this.city=='gatineau' || this.city=='montroyal') && this.report.phase!='' && this.report.close=='') {
        if(this.report.phase=='all') {
          this.errorForm.close=false;
          error=false;
        }else{ 
          this.errorForm.close=true;
          error=true;
        }
      }

      if (error) return;
      let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
      if(viewType === 1){
        window.open("/report/preview?"+queryString, this.$i18n.t('reportProj.textReport'),"_self");
      }else{
        window.open("/report/download?"+queryString, this.$i18n.t('reportProj.textReport'));
      }
    },
    sendEmail(e) {
      // let params = {
      //   to: this.report.to,
      //   subject: this.report.subject,
      //   message: this.report.message,
      //   file: this.report.file,
      // };

      // console.log(params)
      // // create post request
      // axios.post('/report/send', {
      //   data: params
      // })
      // .then(function (response) {
      //   console.log(response);
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });

      let _this = this;
      e.preventDefault();
      this.error=false;
      this.checkForm();
      this.report.to.forEach((element, index) => {
        this.report.to[index] = element.trim();
      });
      if (!this.error) {
        this.loading = true;
        let fd = new FormData();
        fd.append('uuid', this.reportProjectUUID);
        fd.append('ruuid', this.report.ruuid);
        fd.append('zoom', this.report.zoomLevel);
        fd.append('district', this.reportProjectInfo.reportDistrict);
        fd.append('workType', this.reportProjectInfo.reportWorkType);
        fd.append('hour', this.report.hour);
        fd.append('includeMap', this.report.includeMap);
        fd.append('includeAgent', this.report.includeAgent);
        fd.append('typeMap', this.report.typeMap);
        fd.append('modeMap', this.report.modeMap);
        fd.append('phase', this.report.phase);
        fd.append('close', this.report.close);
        fd.append('notice', this.report.notice);
        fd.append('to', this.report.to.join(','));
        fd.append('lang', this.$root.$i18n.locale);
        fd.append('title', this.$i18n.t('reportProj.textTitle'));
        fd.append('message', this.report.message + '<br/><br/><br/><br/>' + this.$i18n.t('reportProj.textNotRep') + '<br/>' + this.$i18n.t('reportProj.team') + '');
        fd.append('subject', this.report.subject);
        if (this.report.file) {
          fd.append('file', this.report.file); 
        }
        axios({
            method: 'post',
            url: 'report/send',
            headers: this.getHeaders(),
            data: fd
        }).then( () => {                        
          //_this.$emit('hideReportProject');
          //this.loading=false;
          _this.hideModal(_this.report.ruuid);
          _this.$notify({
            message: _this.$i18n.t('reportProj.textMsgSuc'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'success'
          });
        })
        .catch( () =>  {
          _this.$notify({
            message: _this.$i18n.t('serverReply.errorReport'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        });
        window.setTimeout(() => {
          _this.loading=false;
          _this.$emit('hideReportProject');
        }, 1000);
      } else {
        console.error(this.error)
      }
    },
    updateSubject() {
      if (this.projectType==='new') {
        this.report.subject=this.$i18n.t('reportProj.new');
      } else {
        this.report.subject=this.$i18n.t('reportProj.update');
      }
    },
    addTag (event) {
      event.preventDefault()
      const emailRegex = /^[\w.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const val = event.target.value.trim()
      if(!val.length || !emailRegex.test(val)) return
      this.report.to.push(val);
      event.target.value = ''
    },
    removeTag (index) {
      this.report.to.splice(index, 1)
    },
    removeLastTag(event) {
      if (!event.target.value.length) {
        this.removeTag(this.report.to.length - 1)
      }
    },
    removeOptionValuesFromTags(options){
      options.forEach(option => {
        this.report.to.forEach((email, index) => {
          if(email === option){
            this.report.to.splice(index, 1)
          }
        })
      })
    },
    changePhase() {
      this.change('phase');
      this.optionsClosing=[];
      this.report.close='';
      let phase = this.closingByPhase[this.report.phase];
      this.optionsClosing=this.closingByPhase[this.report.phase];
      this.newSubject();
    },
    changeClose() {
      this.change('close');
    },
    newSubject() {
      let _this=this;
      this.updateSubject();
      // if (this.report.phase!=='all') {
      //   let res = this.options.filter(function(e) {
      //     if (e.value==_this.report.phase) return e; 
      //   });
      //   if (res && res.length>0) {
      //     this.report.subject = this.report.subject + ' ' + res[0].label;
      //   }
      // }
    }
  }
}
</script>
<style>
  div.report-project-container {
    padding-top: 20px;
    padding-bottom: 20px;
    min-height: 180px;
  }
  
  div.report-project-row {
    margin-top: 20px;
  }
  
  div.report-project-container input.form-control.input-new-tag,
  div.report-project-container  .select-primary.el-select .el-input input,
  div.report-project-container div.input-group-focus input,
  div.report-project-container input {
    color: rgb(82, 95, 127);
  }
  
  div.report-project-container div.vue-input-tag-wrapper {
    padding: 0 !important;
  }
  div.report-project-container div.vue-input-tag-wrapper.form-control input.new-tag {
    padding: 10px 18px 10px 18px;
    font-size: .75rem;
    color: rgb(82, 95, 127);
    margin-bottom: 0px;
  }
  div.report-project-container div.vue-input-tag-wrapper span.input-tag {
    background-color: #e14eca;
    border: 1px solid #e14eca;
    border-radius: 2px;
    color: #FFF;
    border-radius: 12px;
    margin-left: 10px;
    margin-top: 5px;
    height: 24px;
    padding: 0 8px;
    line-height: 22px;
    display: inline-block;
    font-size: 12px;
    border-width: 1px;
    border-style: solid;
    box-sizing: border-box;
    white-space: nowrap;
  }
  div.report-project-container div.vue-input-tag-wrapper span.input-tag a.remove {
    color: #FFF !important;
  }
  div.vue-input-tag-wrapper.form-control {
    border-color: #2b3553 !important;  
  }
  div.report-project-container label.error {
    margin-bottom: 0px;
  }
  button.preview-btn {
    margin-right: 10px;
  }
  form.report-form {
    min-height: 180px;
  }
  div.loading-form {
    position: absolute;
    margin-left: 50%;
    margin-right: -20px;
    top: 50%;
  }
  label.strong-label {
    font-weight: bold;
    font-size: 12px;
  }
  div.report-type-container {
    padding-right: 0px;
    padding-left: 0px;
    display: block ruby;
  }
  div.report-type-container  div.form-check.form-check-radio {
    padding-right: 0px;
  }
  div.modal-custom div.modal-dialog {
    width: 60% !important;
  }
  .emailDropdown {
    width: 50vw !important;
  }
  .emailDropdown>.title{
    font-weight: 400 !important;
  }
  .tag-input {
    width: 100%;
    border: 1px solid #2b3553;
    border-radius: .4285rem;
    font-size: 0.9em;
    height: calc(2.25rem + 2px);
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
  }
  .tag-input__tag {
    height: 25px;
    float: left;
    margin-right: 10px;
    background-color: #e14eca;
    margin-top: 10px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 30px;
    color: white;
  }
  .tag-input__text {
    border: none;
    outline: none;
    font-size: 0.9em;
    line-height: 50px;
    background: none;
    height: calc(2.25rem + 2px);
    flex-grow: 1;
  }
  .tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
  }
</style>