<template>
  <div>
    <modal :show.sync="modalMap" :advancedSettings.sync="advancedSettings" type="custom-map" :scrollToBottom="false" v-on:hideModal="$emit('closeMapModal')">
      <h4 slot="header" class="title title-up"></h4>
      <template slot="close-button">
        <button type="button" class="close" @click="$emit('closeMapModal')" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
      </template>
      <div class="map-content">
        <help-map></help-map>
        <div id="closingView"></div>
        <div class="select-entrave">
          <h4 class="text-center" v-html='closing.label'></h4>
        </div>
      </div>
      <template slot="footer">
          <!--<base-button type="secondary" @click="$emit('closeMapModal')">Fermer</base-button>-->
          <div>
            <base-switch v-model="advancedSettings" on-text="" off-text=""></base-switch>
            <span> {{ $t('mapPage.advancedSettings') }}</span>
          </div>
          <div class="">
            <base-button type="danger" class="btn-margin-right" @click="clearSelect()" >{{ $t('mapPage.btnReset') }}</base-button>
            <!--<base-button type="info" class="btn-margin-right" @click="reverseSelect()" >Inverser</base-button>-->
            <base-button type="primary" @click="$emit('saveMapModal', closing)" >{{ $t('mapPage.btnSave') }}</base-button>
          </div>
      </template>
      <template slot="filters">
        <advanced-filters v-bind:conflictsBuffer="conflictsBuffer" v-bind:layer="layer" v-bind:mapLoad="mapLoad" v-bind:loading="loading" v-bind:date="date" v-bind:selectedElement="selectedClosing"
                          v-bind:ProjectsConflict="ProjectsConflict"
                          v-on:getAdvancedProjects="getAdvancedProjects($event)"
                          v-on:closeAdvancedTab="closeAdvancedTab()"
                          v-on:onChangeLayer="onChangeLayer($event)"
                          v-on:resetAdvancedProjects="resetAdvancedProjects($event)"></advanced-filters>
      </template>
    </modal>
  </div>
</template>
<script>
import {Modal} from 'src/components';
import { loadModules } from 'esri-loader';
import {BaseSwitch} from 'src/components/index';

import HelpMap from './HelpMap.vue';
import AdvancedFilters from './ModalMapAdvancedFilters.vue';
import LoadProjects from './../shared/MapLoadProjects.js';
import RoadNetwork from './../shared/roadNetwork';
import Features from '../shared/features';

export default {
  name: 'modal-map-closing-component',
  components: {
    Modal,
    BaseSwitch,
    HelpMap,
    AdvancedFilters
  },
  props: ['modalMap', 'entraves', 'selectedClosing', 'uuid', 'date', 'perimeter', 'mapMode','layer'],
  computed: {
  },
  data () {
    return {
      selectMode : "segment",
      loading: false,
      modeFilter: false,
      advancedSettings: false,
      mapLoad: false,
      projects: [],
      projectsLayer: null,
      closing: {
        label:'',
        value:'',
        selected: {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      },
      instance: null,
      map: null,
      view: null,
      drawLayer: null,
      downKeys : {
        isDown  : false,
        control : false,
        char    : ''
      },
      entravesLayer:null,
      layerStreets: null,
      possibleSegments: [],
      
      /*selectedSegments: {
        path : [],
        startPoint : null,
        endPoint   : null,
      },*/
      
      mode:0,
      geometryEngine: null,
      viewArmPMEventHdlr:null,
      Graphic:null,
      Polyline : null,
      Point : null,
      layerView:null,
      Extent: null,
      geodesicUtils : null,
      webMercatorUtils : null,
      spatialReference :null,
      hovered: [],
      drawColors: {
        drawing: '#00d6b4',
        selected: '#1f8ef1',
        search  : '#97f0b9'
      },
      selectionDirectionArrowMarker : null,
      selectionStartMark : null,
      pointSelectMark    : null,
      conflictsRadius : 10, //default 10 units conflict radius
      conflictsUnits : "meters",
      ProjectsConflict: [],
      conflictsBuffer : null,
      conflictsGraph: null,
      
      searchBuffer : null,
      searchGraph  : null,
      state : '',
      features: [],
    };
  },
  created() {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.state = window.localStorage.getItem('state') || "";
    this.features = Features[this.instance.city];
  },
  watch: {
    modalMap(val) {
      if (val) { this.loadMap(); this.advancedSettings=true; } else { this.resetModel(); }
    },
    advancedSettings(val) {
      if (!val) {
        let filters = {
          publish: true,
          nopublish: false,
          ranges: this.date,
          entrave:true,
          closing: false,
          detour: false
        };
        this.getAdvancedProjects([filters, 'project']);
      }
    }
  },
  methods: {
    closeModal() {
      $emit('closeMapModal');
    },
    resetModel () {
      this.selectMode = "segment";
      this.closing = {
        label: '',
        selected: {
          path : [],
          startPoint : null,
          endPoint   : null,
          streetsTrack : []
        },
        type: 'linear'
      };
      this.map=null;
      this.view=null;
      this.drawLayer=null;
      this.projectsLayer=null;
      this.downKeys  = {
        isDown  : false,
        control : false,
        char    : ''
      };
      this.entravesLayer=null;
      this.layerStreets=null;
      this.possibleSegments=[];

      this.closing.path = [];
      this.closing.startPoint = null;
      this.closing.endPoint   = null;
      this.closing.streetsTrack = [];

      this.mode=0;
      //this.advancedSettings=false;
      this.mapLoad=false;
      this.modeFilter=false;
      this.loading=false;
      
      this.geometryEngine=null;
      this.viewArmPMEventHdlr=null;
      this.Graphic=null;
      this.Polyline=null;
      this.Point= null;
      this.layerView=null;
      this.Extent=null;
      this.geodesicUtils = null;
      this.webMercatorUtils = null;
      this.spatialReference=null;
      this.hovered=[];
      this.conflictsBuffer = null;
      this.conflictsGraph  = null;
      this.searchBuffer = null;
      this.searchGraph  = null;
    },
    clearSelect() {
      this.resetModel();
      this.loadMap();
    },
    reverseSelect() {
      this.reversePath();
    },
    initLoad(Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, geodesicUtils, Polyline, Point, Graphic, geometryEngine, webMercatorUtils, spatialReference, Extent,VectorTileLayer) {
      
      let user = JSON.parse(window.localStorage.getItem('user'));
      let location = [user.location.longitude, user.location.latitude];
      if(RoadNetwork[this.instance.city].isMultiCity){
        location = [RoadNetwork[this.instance.city].city[this.state].location[0],RoadNetwork[this.instance.city].city[this.state].location[1]];
      }
      //let type = (user.appsettings && user.appsettings.typeMap) || 'osm';
      var basemap;
      if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="1") {
        basemap = new BaseMap({
          portalItem: {id: user.appsettings.map.typeMap}
        });
      }
      else if (user.appsettings.map && user.appsettings.map.modeMap && user.appsettings.map.modeMap==="2"){
        basemap = new BaseMap({
          baseLayers: [
            new VectorTileLayer({
              portalItem: {
                id: user.appsettings.map.typeMap
              }
            })
          ]
        });
      }
      else {
        basemap = (user.appsettings.map && user.appsettings.map.typeMap) || 'osm'; 
      }
      this.map = new Map({
        basemap : basemap
      });
      
      this.view = new MapView({
          container: "closingView",
          map: this.map,
          center: location,
          zoom: 15,
          spatialReference: 102100,
          highlightOptions: {
            color: [255, 255, 255, 0],
            haloOpacity: 0,
            fillOpacity: 0
          }
      });
      
      this.layerStreets = new FeatureLayer({
        url : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].url:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].url:RoadNetwork[this.instance.city].url),
        visible : true,
        outFields: (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].fields : ((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].fields:RoadNetwork[this.instance.city].fields)
      });

      this.drawLayer = new GraphicsLayer();
      this.entravesLayer = new GraphicsLayer();
      this.projectsLayer = new GraphicsLayer();
      this.Graphic=Graphic;
      this.Polyline = Polyline;
      this.Point = Point;
      this.geometryEngine=geometryEngine;
      this.Extent=Extent;
      this.geodesicUtils = geodesicUtils;
      this.webMercatorUtils = webMercatorUtils;
      this.spatialReference=spatialReference;
      this.map.add(this.layerStreets);
      this.map.add(this.projectsLayer);
      this.map.add(this.entravesLayer);
      this.map.add(this.drawLayer);
      
      this.conflictsBuffer = null;
      this.searchBuffer = null;
      this.searchGraph  = null;
    },
    addSearch(Search,Locator) {
      // Search widget
      let search = new Search({
        view : this.view,
        autoSelect : true,
        label : "Some Label",
        locationEnabled : false,
        minSuggestCharacters : 3,
        popupEnabled : false,
        searchAllEnabled : true,
        declaredClass: 'hello',
        includeDefaultSources : (!!this.features.searchSource && this.features.searchSource.isDefaultESRI) || false,
        resultGraphicEnabled : true,
        sources : [],
        resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              }
      });
      if(!!this.features.searchSource && this.features.searchSource.isQuebec){
        search.sources.push(
          {
              locator : new Locator({
                url: "https://servicescarto.mern.gouv.qc.ca/pes/rest/services/Territoire/AdressesQuebec_Geocodage/GeocodeServer"
              }),
              singleLineFieldName: "SingleLine",
              name: "Adresses Quebec Geocodage",
              placeholder: this.$i18n.t('mapPage.serchePlaceHolder'),
              maxResults: 50,
              maxSuggestions: 6,
              outFields: ["*"],
              resultGraphicEnabled: true,
              resultSymbol :{
                type: "picture-marker",
                url: window.location.protocol + '//'+ window.location.host +  "/static/img/icons/pin.png",
                size: 24,
                width: 24,
                height: 24,
                xoffset: 0,
                yoffset: 0
              } 
            }
        )
      }

      if(!!this.features.searchSource && this.features.searchSource.isDefaultCenterline){
        search.sources.push(
          {
            layer : this.layerStreets,
            searchFields : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].searchFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].searchFields:RoadNetwork[this.instance.city].searchFields),
            displayField : (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].displayField:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].displayField:RoadNetwork[this.instance.city].displayField),
            exactMatch   : false,
            prefix: "",
            suffix: "",
            maxResults: 50,
            maxSuggestions: 6,
            name         : "Rue",
            outFields    : ["*"],
            placeholder  : this.$i18n.t('mapPage.serchePlaceHolder'),
            resultGraphicEnabled : false
          }
        )
      }
      let _this = this;
      /*search.on("search-complete", function(event){
        _this.cleanSearchGraph();
        let paths = [], dists = [];
        
        paths = event.results[0].results.map(function(result){
          dists.push(5);
          return result.feature.geometry;
        });
        if (paths.length > 0){
          _this.searchBuffer = _this.geometryEngine.geodesicBuffer(paths, dists, _this.conflictsUnits, true)[0];
          var g = new _this.Graphic({
            geometry : _this.searchBuffer,
            visible : true,
            symbol  : {
              type: "simple-fill",
              color: _this.drawColors.search,
              style: "backward-diagonal",
              outline: {  // autocasts as new SimpleLineSymbol()
                color: _this.drawColors.search,
                width: 3
              }
            }
          });
          _this.drawLayer.add(g);
          _this.searchGraph = g;
        }
        
      });*/
      this.view.ui.add(search, "top-right");
    },
    
    cleanSearchGraph(){
      let _this = this;
      if(_this.searchGraph) {
        _this.drawLayer.remove(_this.searchGraph);
        _this.searchGraph=null;
      }
      if(_this.searchBuffer){
        delete _this.searchBuffer;
        _this.searchBuffer=null;
      }
    },

    viewArmPMEvent() {
      this.viewArmPMEventHdlr = this.view.on("pointer-move", this.viewArmPMEventFun);
    },
    viewArmClkEvent() {
      this.view.on("click", this.viewArmClkFun);
    },
    viewArmDblClkEvent() {
      this.view.on("double-click", this.viewArmDblClkFun);
    },
    viewArmKeyDwnEvent(){
      //this.viewArmKeyDwnEventHdlr = 
      this.view.on("key-down", this.viewArmKeyDwnEventFun);
    },
    
    viewArmKeyUpEvent(){
      //this.viewArmKeyUpEventHdlr =
      this.view.on("key-up", this.viewArmKeyUpEventFun);
    },
    viewUnarmPMEvent () {
      if (this.viewArmPMEventHdlr){
        this.viewArmPMEventHdlr.remove();
        this.viewArmPMEventHdlr = null;
      }
    },
    drawEntraves() {
      let _this = this;
      this.entraves.forEach(function(entrave) {
        var point, segment;
        if (entrave.type=='ponctuel') {
          if(_this.mapMode === "create"){
            point = entrave.selected.path[0];
            segment = entrave.selected.path[0].segment;
          } else {
            let p = entrave.selected.path[0];
            let object = {
              geometry : new _this.Point({
                latitude  : p.geometry.latitude,
                longitude : p.geometry.longitude,
                x : p.geometry.x,
                y : p.geometry.y,
                spatialReference : p.geometry.spatialReference,
                hasM : p.geometry.hasM,
                hasZ : p.geometry.hasZ
              }),
              attributes : p.attributes,
              segment : {
                geometry : new _this.Polyline({
                  paths : p.segment.geometry.paths,
                  spatialReference : p.segment.geometry.spatialReference,
                  hasM : false,
                  hasZ : false
                }),
                attributes : p.segment.attributes
                
              }
            };
            point = object;
            segment = object.segment;
          }
          let graph = new _this.Graphic({
            geometry   : point.geometry,
            attributes : point.attributes,
            visible    : true,
            symbol: {
              type: "simple-marker",
              color: _this.drawColors.selected,
              outline: {
                color: [ 255, 255, 0 ],
                width: 1
              }
            }
          });
          _this.entravesLayer.add(graph);
          
          graph = new _this.Graphic({
            geometry   : segment.geometry,
            attributes : segment.attributes,
            visible    : true,
            symbol : {
              cap   : "round",
              join  : "round",
              type  : "simple-line",
              color : _this.drawColors.selected,
              width : "1px",
              style : "short-dash"
            }
          });
          _this.entravesLayer.add(graph);
        } else {
          entrave.selected.path.forEach(function(element) {
            var geometry;
            if(_this.mapMode === "create"){
              geometry = element.geometry;
            }else {
              geometry = {
                  type : "polyline",
                  paths : element.geometry.paths,
                  spatialReference : element.geometry.spatialReference,
                  hasM : false,
                  hasZ : false
                };
            }
            var graph = new _this.Graphic({
              geometry   : geometry,
              attributes : element.attributes,
              visible    : true,
              symbol : {
                cap   : "round",
                join  : "round",
                type  : "simple-line",
                color : _this.drawColors.selected,
                width : "3px",
                style : "solid"
              }
            });
            _this.entravesLayer.add(graph);
          }); 
        }
      });
    },
    drawSelectedClosingPoint() {
      let _this=this;
      
      this.selectMode = "point";
      _this.mode = 1;
      _this.hovered.forEach(function(g){_this.drawLayer.remove(g);});
      _this.viewUnarmPMEvent();
      
      _this.closing = {
        label: _this.selectedClosing.label,
        value: _this.selectedClosing.value,
        selected: {
          path : _this.selectedClosing.selected.path,
          startPoint : _this.selectedClosing.selected.startPoint,
          endPoint   : _this.selectedClosing.selected.endPoint,
          streetsTrack : _this.selectedClosing.selected.streetsTrack
        },
        type: _this.selectedClosing.type
      };
      /*if (_this.mapMode === "create"){
        _this.closing = _this.selectedClosing;
      }else{
        let p = _this.selectedClosing.selected.path[0];
        _this.closing.label = _this.selectedClosing.label;
        _this.closing.value = _this.selectedClosing.value;
        _this.closing.type  = _this.selectedClosing.type;
        _this.closing.selected.path       = [{
          geometry : new _this.Point({
            latitude  : p.geometry.latitude,
            longitude : p.geometry.longitude,
            x : p.geometry.x,
            y : p.geometry.y,
            spatialReference : p.geometry.spatialReference,
            hasM : p.geometry.hasM,
            hasZ : p.geometry.hasZ
          }),
          attributes : p.attributes,
          segment : {
            geometry : new _this.Polyline({
              paths : p.segment.geometry.paths,
              spatialReference : p.segment.geometry.spatialReference,
              hasM : false,
              hasZ : false
            }),
            attributes : p.segment.attributes
            
          }
        }];
        _this.closing.selected.streetsTrack = [];
      }*/
      
      let point = _this.closing.selected.path[0];
      let segment = _this.closing.selected.path[0].segment;
      let graph = new _this.Graphic({
        geometry   : point.geometry,
        attributes : point.attributes,
        visible    : true,
        symbol: {
          type: "simple-marker",
          color: _this.drawColors.drawing,
          outline: {
            color: [ 255, 255, 0 ],
            width: 1
          }
        }
      });
      _this.drawLayer.add(graph);
      
      graph = new _this.Graphic({
        geometry   : segment.geometry,
        attributes : segment.attributes,
        visible    : true,
        symbol : {
          cap   : "round",
          join  : "round",
          type  : "simple-line",
          color : _this.drawColors.drawing,
          width : "1px",
          style : "short-dash"
        }
      });
      _this.drawLayer.add(graph);
      _this.updateConflictsBuffer();
      _this.view.goTo(_this.drawLayer.graphics.toArray());
      _this.viewUnarmPMEvent();
    },
    drawSelectedClosing() {
      
      let _this=this;
      var sens, start, end;
      _this.mode = 1;
      _this.hovered.forEach(function(g){_this.drawLayer.remove(g);});
      _this.viewUnarmPMEvent();
      
      let suggest = _this.selectedClosing.selected.path[0].suggest;
      
      
      if (_this.mapMode === "edit"){
        _this.closing = JSON.parse(JSON.stringify(_this.selectedClosing));
        _this.closing.label = '';
        _this.closing.value = '';
        _this.closing.type  = _this.selectedClosing.type;
        _this.closing.selected.path       = [];
        _this.closing.selected.streetsTrack = [];
      }else{
        _this.closing = _this.selectedClosing;
      }
      //_this.closing = _this.selectedClosing;
      _this.selectedClosing.selected.path.forEach(function(object) {
        var geometry = object.geometry;
        /*if(_this.mapMode === "create"){
          geometry = object.geometry;
        }else {
          geometry = {
              type : "polyline",
              paths : object.geometry.paths,
              spatialReference : object.geometry.spatialReference,
              hasM : false,
              hasZ : false
            };
        }*/
        var graph = new _this.Graphic({
            geometry   : geometry,
            attributes : object.attributes,
            visible    : true,
            symbol : {
              cap   : "round",
              join  : "round",
              type  : "simple-line",
              color : _this.drawColors.drawing, 
              width : "3px",
              style : "solid"
            }
        });
        if (_this.mapMode === "edit"){
          _this.pushSelectedSegment(graph);
        }
        _this.drawLayer.add(graph);
        //_this.closing.selected.push({geometry: object.geometry, attributes: object.attributes});
        if(suggest !== false){
          _this.getIntersections(_this.layerView, geometry);
        }else{_this.selectMode = "point";}
        //_this.getIntersections(_this.layerView, geometry);
      });
      //drawing new arraow
      var object       = _this.closing.selected.path[_this.closing.selected.path.length - 1];
      var lastPathIdx  = object.geometry.paths.length - 1;
      var lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
      
      if (_this.geometryEngine.equals(_this.closing.selected.endPoint, object.geometry.getPoint(lastPathIdx, lastPointIdx))) {
        sens = "straight";
      }else{
        sens = "reversed";
      }

      // Updating markers and buffers
      [start, end] = _this.getLineEgdes(object.geometry, sens);
      let ang = _this.computeAngle(start, end);
      _this.drawArrowMarker(ang, end);
      
      //_this.closing.selected.streetsTrack.reverse();
      _this.updateStreetsLabel();
      _this.updateConflictsBuffer();
      _this.view.goTo(_this.drawLayer.graphics.toArray());
    },
    esriToTurf(geometry){
      let _this = this;
      let geographic;
      if (geometry.spatialReference.isWebMercator){
        geographic = _this.webMercatorUtils.webMercatorToGeographic(geometry);
      }else{geographic = geometry;}
      var converted;
      switch (geometry.type){
        case "point" :
          converted = turf.point([geographic.longitude, geographic.latitude]);
          break;
        case "polyline" :
          converted = turf.lineString(geographic.paths.flat());
          break;
        default:
          converted = null;
          break;
      }
      return converted;
    },
    splitLine(polyline, point){
      let _this = this;
      //try {
        let tPoint    = _this.esriToTurf(point);
        let tPolyline = _this.esriToTurf(polyline);
        var split = turf.lineSplit(tPolyline, tPoint);
        let tSegment1 = split.features[0];
        let tSegment2 = split.features[1];
        
        var segment1 = _this.webMercatorUtils.geographicToWebMercator(new _this.Polyline({
          paths : [tSegment1.geometry.coordinates],
          //spatialReference : polyline.spatialReference
          spatialReference : _this.spatialReference.WGS84
        }));
        
        var segment2 = _this.webMercatorUtils.geographicToWebMercator(new _this.Polyline({
          paths : [tSegment2.geometry.coordinates],
          //spatialReference : polyline.spatialReference
          spatialReference : _this.spatialReference.WGS84
        }));
        
        return [segment1, segment2];
        
      //}catch(e){console.log("ER: ", e);}  
    },
    subPolyline(polyline, startP, endP){
      let _this = this;
      var subSeg;
      let firstSliceL, firstSliceR, secondSliceL, secondSliceR;
      //try {
      [firstSliceL, firstSliceR]   = _this.splitLine(polyline, startP);
      [secondSliceL, secondSliceR] = _this.splitLine(polyline, endP);
      
      if (_this.geometryEngine.intersects(secondSliceR, startP)){
        //subSeg = _this.completeBorders(_this.geometryEngine.intersect(secondSliceR, firstSliceL), endP, startP);
        subSeg = _this.intersect(secondSliceR, startP);
      }else{
        //subSeg = _this.completeBorders(_this.geometryEngine.intersect(firstSliceR, secondSliceL), startP, endP);
        subSeg = _this.intersect(firstSliceR, endP);
      }
      return subSeg;
      //}catch(e){console.log("SubSeg fails with ", e);}
    },
    completeBorders(polyline, startP, endP){
      let _this = this;
      let path = polyline.paths[0];
      let length = path.length;
      let fp = polyline.getPoint(0,0);
      let ep = polyline.getPoint(polyline.paths.length - 1, length-1);
      var newPath = polyline.paths[0];
      
      if (!_this.geometryEngine.equals(startP, fp)){
        newPath.unshift([startP.x, startP.y]);
      }
      if (!_this.geometryEngine.equals(endP, ep)){
        newPath.push([endP.x, endP.y]);
      }
      
      polyline.paths = [newPath];
      return polyline;
    },
    intersect(polyline, point){
      let _this = this;
      let paths = polyline.paths[0];
      var subPath = [], found = false, simpleLine, simplePath;
      let i = 0, segIdx;
      let d, minD = -1;
      
      while(!found && i < paths.length - 1){
        simplePath = [paths[i], paths[i + 1]];
        simpleLine = new _this.Polyline({
          paths : [simplePath],
          spatialReference : polyline.spatialReference
        });
        if (_this.geometryEngine.intersects(simpleLine, point)){
          found  = true;
          segIdx = i;
        }else{
          d = _this.geometryEngine.distance(simpleLine, point);
          if(minD === -1){
            minD   = d;
            segIdx = i;
          }else{
            if (d < minD){
              minD   = d;
              segIdx = i;
            }
          i++;
          }
        }
      }
      for (i=0; i<= segIdx;i++){
        subPath.push(paths[i]);
      }
      subPath.push([point.x, point.y]);
      var r = new _this.Polyline({
        paths : [subPath],
        spatialReference : polyline.spatialReference
      });
      return r;
    },
    loadMap() {
      loadModules([
      "esri/Map",
      "esri/Basemap",
      "esri/views/MapView",
      "esri/layers/FeatureLayer",
      "esri/layers/GraphicsLayer",
      "esri/geometry/Extent",
      "esri/geometry/Polyline",
      "esri/geometry/Point",
      "esri/tasks/support/Query",
      "esri/geometry/geometryEngine",
      "esri/geometry/support/geodesicUtils",
      "esri/Graphic",
      "esri/geometry/support/webMercatorUtils",
      "esri/geometry/SpatialReference",
      "esri/widgets/Search",
      "esri/layers/VectorTileLayer",
      "esri/tasks/Locator"])
      .then(([Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, Extent, Polyline, Point, Query, geometryEngine, geodesicUtils, Graphic, webMercatorUtils, spatialReference, Search,VectorTileLayer,Locator]) => {
        let _this=this;
        this.initLoad(Map, BaseMap, MapView, FeatureLayer, GraphicsLayer, geodesicUtils, Polyline, Point, Graphic, geometryEngine, webMercatorUtils, spatialReference, Extent,VectorTileLayer);
        this.view.whenLayerView(_this.layerStreets).then(function(layerView){
          _this.mapLoad=true;
          _this.layerView=layerView;
          _this.addSearch(Search,Locator);
          _this.conflictsRadius = _this.perimeter?_this.perimeter:1;
        
          
          _this.viewArmPMEvent();
          _this.viewArmClkEvent();
          //_this.viewArmDblClkEvent();
          _this.viewArmKeyDwnEvent();
          _this.viewArmKeyUpEvent();
          _this.view.focus();
          
          _this.drawEntraves();
          if (_this.selectedClosing && _this.selectedClosing.selected.path.length > 0 ) {
            if(_this.selectedClosing.type=='ponctuel') {
              _this.drawSelectedClosingPoint();
            } else {
              _this.drawSelectedClosing(); 
            }
            _this.view.goTo(_this.drawLayer.graphics.toArray());
            
          } else {
            _this.view.goTo(_this.entravesLayer.graphics.toArray());
          }
        });
      });   
    },
    savePointGeometry(object) {
      this.closing.selected.path = [object];
      this.closing.type = 'ponctuel';
      this.closing.label = this.streetName(object);
      this.closing.value = this.streetName(object);
      this.updateConflictsBuffer();
    },
    viewArmDblClkFun(event) {
      let _this = this;
      event.stopPropagation();
      _this.cleanSearchGraph();
      let query = this.layerStreets.createQuery();
      query.geometry = _this.createPointSearchExtent(event, 5);
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.outFields = (RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].queryOutFields:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].queryOutFields:RoadNetwork[this.instance.city].queryOutFields);
      
      this.layerStreets.queryFeatures(query).then(function(result){
        if (result.features.length){_this.viewUnarmPMEvent();}
        let object = {
          /*geometry: {
            type: "point",
            longitude: event.mapPoint.longitude,
            latitude: event.mapPoint.latitude
          },*/
          geometry : event.mapPoint,
          attributes: result.features[0].attributes,
          segment: result.features[0]
        };
        var graph;
        if (_this.closing.selected.path.length===0) {
          _this.selectMode = "point";
          let nearset = _this.geometryEngine.nearestCoordinate(result.features[0].geometry, event.mapPoint);
          if (nearset && nearset.coordinate && nearset.coordinate.longitude && nearset.coordinate.latitude) {
            object.geometry.longitude = nearset.coordinate.longitude;
            object.geometry.latitude = nearset.coordinate.latitude;
          }
          
          graph = new _this.Graphic({
            geometry   : object.geometry,
            attributes : object.attributes,
            visible    : true,
            symbol: {
              type: "simple-marker",
              color: _this.drawColors.drawing,
              outline: {
                color: _this.drawColors.drawing,
                width: 5
              }
            }
          });
          _this.pointSelectMark = graph;
          _this.drawLayer.add(graph);
          
          graph = new _this.Graphic({
            geometry   : result.features[0].geometry,
            attributes : result.features[0].attributes,
            visible    : true,
            symbol : {
              cap   : "round",
              join  : "round",
              type  : "simple-line",
              color : _this.drawColors.drawing,
              width : "1px",
              style : "short-dash"
            }
          });
          _this.drawLayer.add(graph);
          _this.savePointGeometry(object);
        }else if(_this.closing.selected.path.length === 1){
          let paths = _this.closing.selected.path;
          //if(((_this.closing.type=='ponctuel') || (_this.closing.type=='polyline')) && (result.features[0].attributes.ID==paths[(paths.length-1)].attributes.ID)) {
            
            if(((_this.closing.type=='ponctuel') || (_this.closing.type=='polyline')) && (result.features[0].attributes[RoadNetwork[_this.instance.city].fieldId]==paths[(paths.length-1)].attributes[RoadNetwork[_this.instance.city].fieldId])) {
            let nearset = _this.geometryEngine.nearestCoordinate(result.features[0].geometry, event.mapPoint);

            if (nearset && nearset.coordinate && nearset.coordinate.longitude && nearset.coordinate.latitude) {
              object.geometry.longitude = nearset.coordinate.longitude;
              object.geometry.latitude = nearset.coordinate.latitude;
            }
            
            let firstPoint = _this.closing.selected.path[0].geometry;
            let subSegment = _this.subPolyline(result.features[0].geometry, firstPoint, object.geometry);
            
            object.geometry = subSegment;
              graph = new _this.Graphic({
                geometry   : object.geometry,
                attributes : object.attributes,
                visible    : true,
                symbol : {
                  cap   : "round",
                  join  : "round",
                  type  : "simple-line",
                  color : _this.drawColors.drawing, 
                  width : "3px",
                  style : "solid"
                }
              });
              _this.drawLayer.add(graph);
              _this.drawLayer.remove(_this.pointSelectMark);
              _this.addSubSegment(object);

          } else {
            console.log("différent point...");
          }
          
        }
      });
    },
    viewArmPMEventFun (event) {
      let _this=this;
      // Pointer Move function
      if (_this.hovered) {_this.hovered.forEach(function(g){_this.drawLayer.remove(g);});}
      this.hovered = [];
      event.stopPropagation();
      //viewUnarmPMEvent();
      //latestPoint = view.toMap({x: event.x, y: event.y});
      var query = this.layerStreets.createQuery();
      query.geometry = this.createPointSearchExtent(event, 5);
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.outFields = RoadNetwork[_this.instance.city].queryOutFields;

      this.layerStreets.queryFeatures(query).then(function(result){
        //_this.drawLayer.removeAll();
        result.features.forEach(function(object){
          if (_this.inObstruction(object)){
            //selectedSegments.push(object);
            var graph = new _this.Graphic({
                geometry   : object.geometry,
                attributes : object.attributes,
                visible    : true,
                symbol : {
                  cap   : "round",
                  join  : "round",
                  type  : "simple-line",
                  color : _this.drawColors.drawing,
                  width : "2px",
                  style : "short-dash"
                }
            });
            _this.hovered.push(graph);
            _this.drawLayer.add(graph);
          }
        });
      });
    },
    viewArmClkFun(event) {
      let _this=this;
      _this.cleanSearchGraph();
      if (event.button === 0){
        if(_this.downKeys.control && (_this.selectMode === "point" || _this.closing.selected.path.length === 0)){
          _this.viewArmDblClkFun(event);
        }else{
          //var query = new Query();
          var query = this.layerStreets.createQuery();
          query.geometry = this.createPointSearchExtent(event, 5);
          query.spatialRelationship = "intersects";
          query.returnGeometry = true;
          query.outFields = ["*"];
          
          //layerView.queryFeatures(query).then(function(result){
          var closest = null, refD = -1, d;
          this.layerStreets.queryFeatures(query).then(function(result){
            var fromHover = false;
            if (result.features.length && _this.mode === 0){
              fromHover = true;
              _this.mode = 1; // change to buildingt mode
              _this.hovered.forEach(function(g){_this.drawLayer.remove(g);});
              _this.viewUnarmPMEvent();
            }
            
            result.features.forEach(function(object){
              var p = _this.inPossibleSegment(object);
              //var directionArrwLine, sens, start, end;
              if (_this.inObstruction(object) && !_this.inSelectedSegment(object) && (fromHover || p) ){
                d = _this.geometryEngine.distance(event.mapPoint, object.geometry);
                if (refD === - 1){
                  refD = d;
                  closest = object;
                }else{
                  if(d <= refD ){
                    refD = d;
                    closest = object;
                  }
                }
              }
            });
            if(closest){
              var directionArrwLine, sens, start, end;
              var graph = new _this.Graphic({
                geometry   : closest.geometry,
                attributes : closest.attributes,
                visible    : true,
                symbol : {
                  cap   : "round",
                  join  : "round",
                  type  : "simple-line",
                  color : _this.drawColors.drawing,
                  width : "3px",
                  style : "solid"
                }
              });
              [directionArrwLine, sens] = _this.pushSelectedSegment(graph);
              _this.drawLayer.add(graph);
              //_this.closing.selected.push({geometry: object.geometry, attributes: object.attributes});
              //getIntersections(layerView, createGeomtSearchExtent(object.geometry));
              _this.getIntersections(_this.layerView, closest.geometry);
              
              [start, end] = _this.getLineEgdes(directionArrwLine, sens);
              let ang = _this.computeAngle(start, end);
              _this.drawArrowMarker(ang, end);
              if (!fromHover) {_this.deletePossibleSegments(closest);}
            }
            
            
          });
        }
      } else if (event.button === 2) {
        this.cleanMap();
      }
    },
    
    viewArmKeyDwnEventFun (event){
      let _this = this;
      console.log("KEY", event.key);
      console.log("Repeat: ", event.repeat);
      event.native.preventDefault();
      if (!_this.downKeys.isDown){
        switch (event.key) {
          case 'Control':
            _this.downKeys.control = true;
            break;
          case 'Shift':
            console.log("Shift down");
            _this.downKeys.control = true;
            break;
          case 'i':
            //event.native.preventDefault();
            if(!_this.downKeys.isDown && _this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.reversePath();              
            }
            //else{event.native.preventDefault();}
            break;
          case ' ':
            if(!_this.downKeys.isDown && !_this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.gotoSelection();
            }
            break;
          case 'Backspace':
            if(!_this.downKeys.isDown && !_this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.deleteLast();
            }
            break;
          case 'Delete':
            if(!_this.downKeys.isDown && !_this.downKeys.control && !event.repeat){
              _this.downKeys.isDown = true;
              _this.deleteFirst();
            }
            break;
          default:
            break;
        }
      }
      console.log("Control: ", _this.downKeys.control);
    },
    
    viewArmKeyUpEventFun(){
      let _this = this;
      if((event.key === 'Control') || (event.key === 'Shift')){_this.downKeys.control = false;}
      else{_this.downKeys.isDown  = false;}
    },

    //goto selection
    gotoSelection(){
      let _this = this;
      if (_this.closing.type === "linear"){
        _this.view.goTo(_this.closing.selected.path);
      }else{
        let p = new _this.Point({
          longitude : _this.closing.selected.path[0].geometry.longitude,
          latitude  : _this.closing.selected.path[0].geometry.latitude
        });
        _this.view.goTo(p);
      }
    },
    
    deleteFirst(){
      let _this = this;
      if ((_this.closing.type === "linear") && (_this.closing.selected.path.length> 0)){
        if(_this.closing.selected.path.length === 1){
          _this.cleanMap();
        }else{
          var object = _this.closing.selected.path.shift();
          // remove segment from graph
          _this.drawLayer.remove(object);
          
          // remove possible segments conected to deleted segment
          var possibleToDel = [];
          _this.possibleSegments.forEach(function(possible){
            if (_this.geometryEngine.distance(possible.object.geometry, object.geometry, "meters") <= 10){
              possibleToDel.push(possible);
            }
          });
          possibleToDel.forEach(function(toDel){_this.deletePossibleSegments(toDel.object);});
          // Set new Start Point
          
          //search for connection Point
          var lastPathIdx  = object.geometry.paths.length - 1;
          var lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
          
          var sp = _this.closing.selected.startPoint;
          var objStartPnt = object.geometry.getPoint(0, 0);
          var objEndPnt   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
          
          var connectPoint;
          if (_this.geometryEngine.equals(sp, objStartPnt)){
            connectPoint = objEndPnt;
          }else{
            connectPoint = objStartPnt;
          }
          
          // fins new start point
          var newFirst = _this.closing.selected.path[0];
          lastPathIdx  = newFirst.geometry.paths.length - 1;
          lastPointIdx = newFirst.geometry.paths[lastPathIdx].length - 1;
          
          var newFirstStart = newFirst.geometry.getPoint(0, 0);
          var newFirstEnd   = newFirst.geometry.getPoint(lastPathIdx, lastPointIdx);
          var newStart;
          if (_this.geometryEngine.distance(connectPoint, newFirstStart) <= 5){
            newStart = newFirstStart;
          }else{
            newStart = newFirstEnd;
          }
          
          //save new Start and draw new possible segments
          _this.closing.selected.startPoint = newStart;
          _this.getIntersections(_this.layerView, newStart);
          
          _this.drawLayer.remove(_this.selectionStartMark);
          _this.selectionStartMark = _this.drawPointMarker(_this.closing.selected.startPoint, {
            type  : "simple-marker",
            color : _this.drawColors.drawing,
            angle : 0,
            style : "circle",
            size  : "20px",
            outlineColor : _this.drawColors.drawing,
            outlineWidth : "0px"
          });
          
          _this.removeStreetsTrackSegment(object, "start");
          _this.updateStreetsLabel();
          //update conflicts buffer
          _this.updateConflictsBuffer();
          
        }
      }else if(_this.closing.type === "ponctuel"){
       _this.cleanMap(false); 
      }
      
    },
    
    deleteLast(){
      let _this = this;
      if ((_this.closing.type === "linear") && (_this.closing.selected.path.length> 0)){
        if(_this.closing.selected.path.length === 1){
          _this.cleanMap();
        }else{
          var object = _this.closing.selected.path.pop();
          // remove segment from graph
          _this.drawLayer.remove(object);
          
          // remove possible segments conected to deleted segment
          var possibleToDel = [];
          _this.possibleSegments.forEach(function(possible){
            if (_this.geometryEngine.distance(possible.object.geometry, object.geometry, "meters") <= 10){
              possibleToDel.push(possible);
            }
          });
          possibleToDel.forEach(function(toDel){_this.deletePossibleSegments(toDel.object);});
          // Set new End Point
          
          //search for connection Point
          var lastPathIdx  = object.geometry.paths.length - 1;
          var lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
          
          var ep = _this.closing.selected.endPoint;
          var objStartPnt = object.geometry.getPoint(0, 0);
          var objEndPnt   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
          
          var connectPoint;
          if (_this.geometryEngine.equals(ep, objStartPnt)){
            connectPoint = objEndPnt;
          }else{
            connectPoint = objStartPnt;
          }
          
          // fins new start point
          var newLast = _this.closing.selected.path[_this.closing.selected.path.length-1];
          lastPathIdx  = newLast.geometry.paths.length - 1;
          lastPointIdx = newLast.geometry.paths[lastPathIdx].length - 1;
          
          var newLastStart = newLast.geometry.getPoint(0, 0);
          var newLastEnd   = newLast.geometry.getPoint(lastPathIdx, lastPointIdx);
          var newEnd;
          let sens;
          
          if (_this.geometryEngine.distance(connectPoint, newLastStart) <= 5){
            sens = "reversed";
            newEnd = newLastStart;
          }else{
            sens = "straight";
            newEnd = newLastEnd;
          }
          
          //save new Start and draw new possible segments
          _this.closing.selected.endPoint = newEnd;
          _this.getIntersections(_this.layerView, newEnd);
          
          let start, end;
          [start, end] = _this.getLineEgdes(newLast.geometry, sens);
          let ang = _this.computeAngle(start, end);
          _this.drawLayer.remove(_this.selectionDirectionArrowMarker);
          _this.selectionDirectionArrowMarker = _this.drawPointMarker(newEnd,
          {
            type  : "simple-marker",
            color : _this.drawColors.drawing,
            angle : ang,
            style : "triangle",
            size  : "15px",
            outlineColor : _this.drawColors.drawing,
            outlineWidth : "0px"
          });
          
          _this.removeStreetsTrackSegment(object, "end");
          _this.updateStreetsLabel();
          //update conflicts buffer
          _this.updateConflictsBuffer();
          
        }
      }else if(_this.closing.type === "ponctuel"){
       _this.cleanMap(false); 
      }
      
    },
    
    cleanMap(reload) {
      let doReload = reload || false;
      
      this.selectMode = "segment";
      delete this.closing.selected.startPoint;
      delete this.closing.selected.endPoint;
      delete this.closing.selected.path;
      delete this.closing.selected.streetsTrack;
      
      this.drawLayer.removeAll();
      this.closing.selected.startPoint=null;
      this.closing.selected.endPoint = null;
      this.closing.selected.path = [];
      this.closing.selected.streetsTrack = [];
      this.closing.label='';
      this.closing.value='';

      this.possibleSegments = [];
      this.conflictsBuffer = null;
      this.conflictsGraph  = null;
      this.mode = 0;
      this.viewArmPMEvent();
      
      if ( doReload && this.mapMode === "edit"){
        if (this.closing.type === "linear"){
          this.drawSelectedClosing();
        }else{
          this.drawSelectedClosingPoint();
        }
      }
    },
    
    getIntersections(layerView, geometry) {
      var _this=this;
      var query = _this.layerStreets.createQuery();
      query.geometry = geometry;
      query.spatialRelationship = "intersects";
      query.returnGeometry = true;
      query.outFields = ["*"];
      query.distance = 5;
      query.units = "meters";
      
      _this.layerStreets.queryFeatures(query).then(function(result){
        result.features.forEach(function(object){
          if (!_this.inSelectedSegment(object)){
            var graphic = new _this.Graphic({
              geometry   : object.geometry,
              attributes : object.attributes,
              visible    : true,
              symbol : {
                cap   : "round",
                join  : "round",
                type  : "simple-line",
                color : _this.drawColors.drawing,
                width : "1px",
                style : "short-dash"
              }
            });
            if (_this.pushPossibleSegments(object,graphic)){
              _this.drawLayer.add(graphic);
            }
          }
        });
      });
    },
    inSelectedSegment(object) {
      let _this = this;
      var inSeg;
      /*let selectedStreet='';
      this.selectedSegments.path.forEach(function(element, i) {
        if (i===0) {
          selectedStreet+=element.attributes.NOM_TOPOGRAPHIE;
        } else {
          selectedStreet+='---'+element.attributes.NOM_TOPOGRAPHIE;
        }
        
      });
      this.closing.label=selectedStreet;*/
      return _this.closing.selected.path.some(function(selectedObject){
        inSeg = _this.geometryEngine.equals(object.geometry, selectedObject.geometry);
        return inSeg;
      });
    },
    
    inObstruction(object){
      //var inObs;
      //let _this = this;
      //var i =  _this.entraves.some(function(entrave){
      //  return entrave.selected.some(function(obstructionSeg){
      //    inObs = _this.geometryEngine.equals(object.geometry, obstructionSeg.geometry);
      //    return inObs;
      //  });
      //});
      //return i;
      return true;
    },
    
    getLineEgdes(line, sens){
      var x;
      if((line.type = "polyline")){
        let s = sens?sens:"straight";
        if (s == "straight"){
          let length = line.paths.length;
          let last   = line.paths[length - 1];
          x = [line.getPoint(length - 1, last.length - 2), line.getPoint(length - 1, last.length - 1)];
          return x;
        }else{
          x = [line.getPoint(0, 1), line.getPoint(0, 0)];
          return x;
        }
      }else{throw("getLineEgdes: bad argument ", line);}
    },

    computeAngle(pointA, pointB){
      let _this = this;
      //var dLon = (pointB.x - pointA.x) * Math.PI / 180;
      //var lat1 = pointA.y * Math.PI / 180;
      //var lat2 = pointB.y * Math.PI / 180;
      //var y = Math.sin(dLon) * Math.cos(lat2);
      //var x = Math.cos(lat1)*Math.sin(lat2) - Math.sin(lat1)*Math.cos(lat2)*Math.cos(dLon);
      //var bearing = Math.atan2(y, x)  * 180 / Math.PI;
      //return ((bearing + 360) % 360).toFixed(1);

      var A = _this.webMercatorUtils.webMercatorToGeographic(pointA);
      var B = _this.webMercatorUtils.webMercatorToGeographic(pointB);

      let geoRes = _this.geodesicUtils.geodesicDistance(A,B);
      return geoRes.azimuth;
    },
    
    drawPointMarker(point, options){
      let _this = this;
      var graph;

      graph =  new _this.Graphic({
        visibility : true,
        geometry   : point,
        symbol     : {
          type  : options.type?options.type:"simple-marker",
          color : options.color?options.color:_this.drawColors.drawing,
          angle : options.angle,
          style : options.style,
          size  : options.size,
          outline : {
            color : options.outlineColor,
            width : options.outlineWidth
          }
        }
      });

      //_this.drawLayer.remove(_this.selectionStartMark);
      //_this.drawLayer.remove(_this.selectionEndMark);

      

      //_this.selectionStartMark = sgraph;
      _this.drawLayer.add(graph);
      //_this.drawLayer.add(sgraph);
    return graph;
    },

    streetName(object){
      return object.attributes[(RoadNetwork[this.instance.city].isMultiCity)?RoadNetwork[this.instance.city].city[this.state].displayField:((RoadNetwork[this.instance.city].isMultiLayer)?RoadNetwork[this.instance.city].layers[this.layer].displayField:RoadNetwork[this.instance.city].displayField)];
    },
    
    updateStreetsLabel(){
      let _this = this;
      var text = '', textHtml= '', street, streetS, streetE;

      if (_this.closing.selected.streetsTrack.length === 1){
        street = _this.closing.selected.streetsTrack[0];
        textHtml = street.name + ' <span class="paths-distance">[' + _this.$i18n.t('projectForm.on') + ' ' + Math.floor(street.distance) + ' m]</span> ';
        text = street.name + ' [' + _this.$i18n.t('projectForm.on') + ' ' + Math.floor(street.distance) + ' m] ';
      }else if(_this.closing.selected.streetsTrack.length > 1){
        streetS = _this.closing.selected.streetsTrack[0];
        streetE = street = _this.closing.selected.streetsTrack[street = _this.closing.selected.streetsTrack.length - 1];
        textHtml = '<span class="paths-keys">' + _this.$i18n.t('projectForm.from')+ ' </span>' + streetS.name + ' <span class="paths-distance">[' + Math.floor(streetS.distance) + 'm]</span> ';
        text =  _this.$i18n.t('projectForm.from') + ' ' + streetS.name + ' [' + Math.floor(streetS.distance) + 'm] ';
        for (var i =1; i < _this.closing.selected.streetsTrack.length - 1; i++){
          street = _this.closing.selected.streetsTrack[i];
          textHtml += '<span class="paths-keys">  &xrarr; </span>' + street.name + ' <span class="paths-distance">[' + Math.floor(street.distance) + 'm]</span> ';
          text += '  &xrarr; ' + street.name + ' [' + Math.floor(street.distance) + 'm] ';
        }
        textHtml += ' <span class="paths-keys">' + _this.$i18n.t('projectForm.to')+ ' </span>' + streetE.name + ' <span class="paths-distance">[' + Math.floor(streetE.distance) + 'm]</span> ';
        text +=   _this.$i18n.t('projectForm.to') + ' ' + streetE.name + ' [' + Math.floor(streetE.distance) + 'm] ';
      }
      _this.closing.label = textHtml;
      _this.closing.value = text;
    },
    
    updatedStreetsTrack(street, object, where){
      let _this = this;
      let newSegment, replace, sName;
      var oldPath = _this.closing.selected.streetsTrack;

      sName = _this.streetName(object);
      if (sName == street.name){
        newSegment = {name : street.name, distance : street.distance + _this.geometryEngine.geodesicLength(object.geometry, "meters")};
        replace = false;
      }else{
        newSegment = {name : _this.streetName(object), distance : _this.geometryEngine.geodesicLength(object.geometry, "meters")};
        replace = true;
      }
      
      // add object in seleted segments in the right order
      if (where == "first"){
        _this.closing.selected.path.unshift(object);
      }else{
        _this.closing.selected.path.push(object);
      }
      
      // update street tarck
      if ( (where == "first") && !replace ){
        oldPath[0] = newSegment;
      } else if ( (where == "first") && replace){
        //oldPath.shift();
        oldPath.unshift(newSegment);
      } else if ( (where == "last") && !replace){
        oldPath[oldPath.length - 1] = newSegment;
      } else{
        //oldPath.pop();
        oldPath.push(newSegment);
      }
      _this.updateStreetsLabel();
      return oldPath;
    },
    removeStreetsTrackSegment(object, where){
      let _this = this;
      if (_this.closing.selected.streetsTrack && _this.closing.selected.streetsTrack.length){
        var changedSeg;
        if (where === "start"){changedSeg = _this.closing.selected.streetsTrack[0];}
        else{changedSeg = _this.closing.selected.streetsTrack[_this.closing.selected.streetsTrack.length - 1];}
        let sName = _this.streetName(object);
        if(sName === changedSeg.name){
          var dist = Math.floor(changedSeg.distance - _this.geometryEngine.geodesicLength(object.geometry, "meters"));
          if(( dist <= 0) && (where === "start")){_this.closing.selected.streetsTrack.shift();}
          else if((dist <= 0) && (where === "end")){_this.closing.selected.streetsTrack.pop();}
          else{
            changedSeg.distance = dist;
            if (where === "start"){_this.closing.selected.streetsTrack[0] = changedSeg;}
            else{_this.closing.selected.streetsTrack[_this.closing.selected.streetsTrack.length - 1] = changedSeg;}
          }
        }
      }
    },
   
    reversePath(){
      let _this=this;
      if ((_this.closing.type!='ponctuel') && (_this.closing.selected.path)  && (_this.closing.selected.path.length > 0)){
        var swap, sens, start, end;
        var lastPathIdx, lastPointIdx, object;
        
        // reverse path sens
        swap = _this.closing.selected.startPoint;
        _this.closing.selected.startPoint = _this.closing.selected.endPoint;
        _this.closing.selected.endPoint = swap;
        _this.closing.selected.path.reverse();
        
        //drawing new arraow
        object       = _this.closing.selected.path[_this.closing.selected.path.length - 1];
        lastPathIdx  = object.geometry.paths.length - 1;
        lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
        
        if (_this.geometryEngine.equals(_this.closing.selected.endPoint, object.geometry.getPoint(lastPathIdx, lastPointIdx))) {
          sens = "straight";
        }else{
          sens = "reversed";
        }

        [start, end] = _this.getLineEgdes(object.geometry, sens);
        let ang = _this.computeAngle(start, end);
        _this.drawArrowMarker(ang, end);
        _this.closing.selected.streetsTrack.reverse();
        _this.updateStreetsLabel();
      }
    },
    
    drawArrowMarker(ang, end) {
      let _this = this;
      _this.drawLayer.remove(_this.selectionDirectionArrowMarker);
      _this.selectionDirectionArrowMarker = _this.drawPointMarker(end,
        {
          type  : "simple-marker",
          color : _this.drawColors.drawing,
          angle : ang,
          style : "triangle",
          size  : "15px",
          outlineColor : _this.drawColors.drawing,
          outlineWidth : "0px"
        });
      
        _this.drawLayer.remove(_this.selectionStartMark);
        _this.selectionStartMark = _this.drawPointMarker(_this.closing.selected.startPoint, {
          type  : "simple-marker",
          color : _this.drawColors.drawing,
          angle : 0,
          style : "circle",
          size  : "20px",
          outlineColor : _this.drawColors.drawing,
          outlineWidth : "0px"
        });  
    },
    updateConflictsBuffer(){
      let _this = this;
      if (_this.closing.selected.path && (_this.closing.selected.path.length > 0)){
        var geoms, dists = [];
        geoms = _this.closing.selected.path.map(function(object){
          dists.push(_this.conflictsRadius);
          return object.geometry;
        });
        if (_this.conflictsBuffer){_this.conflictsBuffer=null;}
        if (_this.conflictsGraph){_this.drawLayer.remove(_this.conflictsGraph);}
        var buff =  _this.geometryEngine.geodesicBuffer(geoms, dists, _this.conflictsUnits, true);
        _this.conflictsBuffer = buff[0];
        var g = new _this.Graphic({
          geometry : _this.conflictsBuffer,
          visible : true,
          symbol  : {
            type: "simple-fill",
            //color: [ 51,51, 204, 0.9 ],
            style: "none",
            outline: {  // autocasts as new SimpleLineSymbol()
              color: _this.drawColors.selected,
              width: 2
            }
          }
        });
        _this.drawLayer.add(g);
        _this.conflictsGraph = g;
      }else{console.log("No Path");}
    },
    getProjects(filters, type) {
      let _this=this;
      LoadProjects.loadProjects(filters, function(success, data) {
        if (success) {
          _this.projects=data.filter((e) => { if (e.uuid!==_this.uuid) return e;});
          _this.projects = _this.projects.filter(item => !!item.data.projectType?item.data.projectType.isPublished:true)

          _this.drawLoadingProjects(filters);
          if (type=='conflict') {
            _this.checkConflicts(filters);
          }
          if ((type=='project') && (_this.projects.length==0)) {
            _this.$notify({
              message: _this.$i18n.t('serverReply.errorConflictReadQrchive'),
              timeout: 30000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'info'
            });
          }
          _this.loading=false;
        } else {
          _this.$notify({
            message: _this.$i18n.t('serverReply.errorProject'),
            timeout: 30000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        }
      });
    },
    drawLoadingProjects(filters) {
      LoadProjects.drawProjects(filters, this.projects, this.projectsLayer, this.Point, this.Polyline, this.Graphic, this.geometryEngine, this.webMercatorUtils, this.geodesicUtils);
    },
    closeAdvancedTab() {
      this.resetAdvancedProjects();
      this.advancedSettings=false;
    },
    onChangeLayer(value){
      this.layer = value;
      this.loadMap();
    },
    resetAdvancedProjects() {
      this.projects=[];
      this.ProjectsConflict=[];
      this.loading=false;
      if (this.projectsLayer) this.projectsLayer.removeAll();
    },
    getAdvancedProjects(params) {
      this.resetAdvancedProjects();
      this.loading=true;
      this.getProjects(params[0], params[1]);
    },
    checkConflicts(filters){
      this.ProjectsConflict=LoadProjects.checkConflicts('closing', filters, this.uuid, this.projects, this.conflictsBuffer, this.conflictsUnits, this.geometryEngine);
    },
    pushSelectedSegment(object){
      let _this = this;
      var lastPathIdx, lastPointIdx, sName, street;
      lastPathIdx  = object.geometry.paths.length - 1;
      lastPointIdx = object.geometry.paths[lastPathIdx].length - 1;
      if (!_this.closing.selected.path || !_this.closing.selected.path.length)
      {
        _this.closing.selected.path = [object];
        _this.closing.selected.startPoint = object.geometry.getPoint(0, 0);
        _this.closing.selected.endPoint   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
        sName = _this.streetName(object);
        _this.closing.selected.streetsTrack = [{name : sName, distance : _this.geometryEngine.geodesicLength(object.geometry, "meters")}];
        _this.updateStreetsLabel();
      }else{
        // Get the closest path extremity to the selected segment
        /***
         *cs : closest point to path start
         *ce : closest point to path end
         *ds : distance between start point and cs
         *de : distance between end point and ce
        ***/
        var cs, ce, ds, de;
        cs = _this.geometryEngine.nearestCoordinate(object.geometry, _this.closing.selected.startPoint);
        ce = _this.geometryEngine.nearestCoordinate(object.geometry, _this.closing.selected.endPoint);
        ds = _this.geometryEngine.distance(_this.closing.selected.startPoint, cs.coordinate, "meters");
        de = _this.geometryEngine.distance(_this.closing.selected.endPoint, ce.coordinate, "meters");
        
        var objStartPnt = object.geometry.getPoint(0, 0);
        var objEndPnt   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
        var objSd, objEd;
        if (ds > de){ // end point is the closest
          objSd = _this.geometryEngine.distance(objStartPnt, ce.coordinate, "meters");
          objEd = _this.geometryEngine.distance(objEndPnt,   ce.coordinate, "meters");
          street = _this.closing.selected.streetsTrack[_this.closing.selected.streetsTrack.length - 1];
          if ((objSd <= 10) || (objEd <= 10) ){
            if(objSd < objEd){// if end point is closest to segment start point, segment end point will be the new path end point
              _this.closing.selected.endPoint = objEndPnt;
            }else{// if end point is closest to segment end point, segment start point will be the new path end point
              _this.closing.selected.endPoint = objStartPnt;
            }
          _this.closing.selected.streetsTrack = _this.updatedStreetsTrack(street, object, "last");
          }else{console.log("Object not connected to path");}
        }else{ // start point is the closest
          objSd = _this.geometryEngine.distance(objStartPnt, cs.coordinate, "meters");
          objEd = _this.geometryEngine.distance(objEndPnt,   cs.coordinate, "meters");
          street = _this.closing.selected.streetsTrack[0];
          if ((objSd <= 10) || (objEd <= 10) ){
            if(objSd < objEd){// if start point is closest to segment start point, segment end point will be the new path start point
              _this.closing.selected.startPoint = objEndPnt;
            }else{// if start point is closest to segment end point, segment start point will be the new path start point
              _this.closing.selected.startPoint = objStartPnt;
            }
          _this.closing.selected.streetsTrack = _this.updatedStreetsTrack(street, object, "first");
          }else{console.log("Object not connected to path");}
        }
      }
    
      lastPathIdx  = _this.closing.selected.path[_this.closing.selected.path.length - 1].geometry.paths.length - 1;
      lastPointIdx = _this.closing.selected.path[_this.closing.selected.path.length - 1].geometry.paths[lastPathIdx].length - 1;
      
      _this.updateConflictsBuffer();
      if (_this.geometryEngine.equals(_this.closing.selected.endPoint, _this.closing.selected.path[_this.closing.selected.path.length - 1].geometry.getPoint(lastPathIdx, lastPointIdx))){
        return [_this.closing.selected.path[_this.closing.selected.path.length - 1].geometry, "straight"];
      }else{
        return [_this.closing.selected.path[_this.closing.selected.path.length - 1].geometry, "reversed"];
      }
    },
    addSubSegment(object){
      let _this = this;
      let sName, lastPathIdx, lastPointIdx, start, end, sens;

      _this.closing.type = "linear";
      object.suggest = false;
      _this.closing.selected.path = [object];
      lastPathIdx  = _this.closing.selected.path[_this.closing.selected.path.length - 1].geometry.paths.length - 1;
      lastPointIdx = _this.closing.selected.path[_this.closing.selected.path.length - 1].geometry.paths[lastPathIdx].length - 1;
      
      _this.closing.selected.startPoint = object.geometry.getPoint(0, 0);
      _this.closing.selected.endPoint   = object.geometry.getPoint(lastPathIdx, lastPointIdx);
      
      sName = _this.streetName(object);
      _this.closing.selected.streetsTrack = [{name : sName, distance : _this.geometryEngine.geodesicLength(object.geometry, "meters")}];
      _this.updateStreetsLabel();
      
      _this.updateConflictsBuffer();
      if (_this.geometryEngine.equals(_this.closing.selected.endPoint, _this.closing.selected.path[_this.closing.selected.path.length - 1].geometry.getPoint(lastPathIdx, lastPointIdx))){
        sens = "straight";
      }else{
        sens = "reversed";
      }
      
      [start, end] = _this.getLineEgdes(object.geometry, sens);
      let ang = _this.computeAngle(start, end);
      _this.drawArrowMarker(ang, end);
    },

    inPossibleSegment(object) {
      let inPoss;
      let _this=this;
      inPoss = _this.possibleSegments.some(function(selected){
        return _this.geometryEngine.equals(object.geometry, selected.object.geometry);
      });
      return inPoss;
    },
    pushPossibleSegments(object, graph) {
      let inPoss = false;
      let _this=this;

      inPoss = _this.possibleSegments.some(function(selected){
        return _this.geometryEngine.equals(object.geometry, selected.object.geometry);
      });
      if (!inPoss){
        _this.possibleSegments.push({object : object, graphic : graph});
        return true;
      }
      else{// If in possible segment, must be removed
        _this.deletePossibleSegments(object);
        return false;
      }
    },

    deletePossibleSegments(object) {
      let _this=this;
      let toShift;
      var newPossible = [];
      _this.possibleSegments.forEach(function(selected){
        if (_this.geometryEngine.equals(selected.object.geometry, object.geometry)){
          toShift = selected;
        }else{
          newPossible.push(selected);
        }
      });
      delete _this.possibleSegments;
      _this.possibleSegments = newPossible;
      if (toShift){
        _this.drawLayer.remove(toShift.graphic);
      }
    },

    createPointSearchExtent(event, pixelTolerance) {
      //the lower left corner
      let lowerLeft = this.view.toMap({
        x: event.x - pixelTolerance,
        y: event.y - pixelTolerance
      });
      
      //the upper right corner
      let upperRight = this.view.toMap({
        x: event.x + pixelTolerance,
        y: event.y + pixelTolerance
      });

      //create the search extent
      return new this.Extent({
        xmin: lowerLeft.x,
        ymin: lowerLeft.y,
        xmax: upperRight.x,
        ymax: upperRight.y,
        spatialReference: 102100
      });
    }
  },
  mounted() {}
}
</script>
<style>
  .modal-content .modal-header button.close {
    top: 5px !important;
  }
   div.modal-footer {
    border-top: 1px solid #b0b0b1 !important;
    padding-top: 16px !important;
  }
  
  div.right-btn {
    position: absolute;
    right: 30px;
    top: -35px;
  }
  
  ul.esri-search__suggestions-list li,
  ul.esri-search__suggestions-list li strong {
    color: #000 !important;
  }
   #closingView {
    padding: 0;
    margin: 0;
    height: 80%;
    width: 100%;
  }
  .select-closing {
    height: 20%
  }
</style>