<template>
  <base-nav
    v-model="showMenu"
    class="navbar-absolute top-navbar"
    type="white"
    :transparent="city==='safelane' ? false : true"
    :type="city==='safelane' ? 'dark' : 'white'">
    <div slot="brand" class="navbar-wrapper">
      <!--<div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>-->
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <!--<img style="margin-left: 70px;" src="../../../public/img/logoWhite.png"/>-->
      <!--<a class="navbar-brand" href="#app">{{ routeName }}</a>-->
      <div v-if="city=='bell'" class="navbar-logo-bell"></div>
      <div v-else-if="city==='safelane'" class="navbar-logo-safelane"></div>
      <div v-else class="navbar-logo"></div>
    </div>
  
    <ul class="navbar-nav" :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'">
      <base-dropdown
        tag="li"
        v-if="this.isDisplayCity === true"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item dropdown-language">
        <template slot="title">
          <!--<img v-if="$root.$i18n.locale=='en'" src="../../../public/img/US.png"/>-->
          <!--<img v-else src="../../../public/img/FR.png"/>-->
          <span><i class="tim-icons icon-square-pin"></i> &nbsp; <span>{{ this.state }}</span></span>
          <!-- <span v-else><i class="tim-icons icon-square-pin"></i> &nbsp; <span>North Carolina</span></span> -->

        </template>
        <div v-for="(item,index) in cityToDisplay" v-bind:index="index" v-bind:item="item" v-bind:key="index" >
          <li class="nav-link lang-item">
            <a class="nav-item dropdown-item"  @click="changeState(item.value)" ><!--<img src="../../../public/img/FR.png"/>--><span>{{ item.value }}</span></a>
          </li>
        </div>
  
      </base-dropdown>
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        title-classes="nav-link"
        class="nav-item dropdown-language">
        <template slot="title">
          <!--<img v-if="$root.$i18n.locale=='en'" src="../../../public/img/US.png"/>-->
          <!--<img v-else src="../../../public/img/FR.png"/>-->
          <span v-if="$root.$i18n.locale=='en'"><i class="tim-icons icon-planet"></i> &nbsp; <span>English</span></span>
          <span v-else><i class="tim-icons icon-planet"></i> &nbsp; <span>Français</span></span>
          <p class="d-lg-none">{{$root.$i18n.locale}}</p>
        </template>
        <li class="nav-link lang-item">
          <a class="nav-item dropdown-item" @click="changeLanguage('fr')" ><!--<img src="../../../public/img/FR.png"/>--><span>Français</span></a>
        </li>
        <li class="nav-link lang-item">
          <a class="nav-item dropdown-item" @click="changeLanguage('en')"><!--<img src="../../../public/img/US.png"/>--><span>English</span></a>
        </li>
      </base-dropdown>
      <!-- <base-button  v-if="(features.feedbackOnSidebar && features.feedbackOnSidebar.show)" @click="$router.push('/feedback/create')" type="link" icon style="margin:2px 0 0 0">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" height="20px"  x="0px" y="0px" viewBox="0 0 112.77 122.88" style="enable-background:new 0 0 112.77 122.88" xml:space="preserve" :class="darkMode ? 'feedback-light-icon' : 'feedback-dark-icon'">
            <g><path d="M64.44,61.11c1.79,0,3.12-1.45,3.12-3.12c0-1.78-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12 c0,1.78,1.45,3.12,3.12,3.12H64.44L64.44,61.11L64.44,61.11L64.44,61.11z M77.45,19.73l18.1-19.14c0.69-0.58,1.39-0.81,2.2-0.35 l14.56,14.1c0.58,0.69,0.69,1.5-0.12,2.31L93.75,36.14L77.45,19.73L77.45,19.73L77.45,19.73L77.45,19.73z M87.74,42.27l-18.66,3.86 l2.36-20.28L87.74,42.27L87.74,42.27z M19.14,13.09h41.73l-3.05,6.45H19.14c-3.48,0-6.65,1.43-8.96,3.73s-3.73,5.46-3.73,8.96 v45.74c0,3.48,1.43,6.66,3.73,8.96c2.3,2.3,5.47,3.73,8.96,3.73h3.72v0.01l0.21,0.01c1.77,0.12,3.12,1.66,2.99,3.43l-1.26,18.1 L48.78,97.7c0.58-0.58,1.38-0.93,2.27-0.93h37.32c3.48,0,6.65-1.42,8.96-3.73c2.3-2.3,3.73-5.48,3.73-8.96V50.45h6.68v42.69 c0.35,9.63-3.58,15.04-19.43,15.7l-32.25-0.74l-32.73,13.87l-0.16,0.13c-1.35,1.16-3.38,1-4.54-0.36c-0.57-0.67-0.82-1.49-0.77-2.3 l1.55-22.34h-0.26c-5.26,0-10.05-2.15-13.52-5.62C2.15,88.03,0,83.24,0,77.98V32.23c0-5.26,2.15-10.05,5.62-13.52 C9.08,15.24,13.87,13.09,19.14,13.09L19.14,13.09L19.14,13.09z M79.69,78.42c1.79,0,3.12-1.45,3.12-3.12 c0-1.79-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12c0,1.78,1.45,3.12,3.12,3.12H79.69L79.69,78.42L79.69,78.42 L79.69,78.42z M50.39,43.81c1.78,0,3.12-1.45,3.12-3.12c0-1.67-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12 c0,1.78,1.45,3.12,3.12,3.12H50.39L50.39,43.81L50.39,43.81L50.39,43.81z"/></g>
          </svg>
      </base-button> -->
      
      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar">
        <template slot="title">
          <div class="photo">
            <i class="tim-icons icon-single-02"></i>
            <!--<img src="img/default-avatar.png" />-->
          </div>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none" @click="logout()">{{ $t('navBar.logoutItem') }}</p>
        </template>
        <!--<li class="nav-link">
          <a href="#" @click="changeLanguage()" class="nav-item dropdown-item">{{ $t('navBar.profilItem') }}</a>
        </li>-->
        <li class="nav-link">
          <a  href="#" @click="showModalSettings" class="nav-item dropdown-item">{{ $t('navBar.settingsItem') }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a  href="#" @click="showModalPublicPage" class="nav-item dropdown-item">{{ $t('navBar.publicItem') }}</a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click="logout()">{{ $t('navBar.logoutItem') }}</a>
        </li>
      </base-dropdown>
    </ul>
    <app-settings v-bind:appSettings="appSettings" v-on:hideAppSettings="hideAppSettings($event)" /></app-settings>
    <public-page v-bind:publicPage="publicPage" v-on:hidePublicPage="hidePublicPage($event)" /></public-page>
  </base-nav>
</template>
<script>
import axios from 'axios';
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav } from '@/components';
import SidebarToggleButton from './SidebarToggleButton';
import AppSettings from './AppSettings';
import PublicPage from './PublicPageModal';
import RoadNetwork from './../shared/roadNetwork';

export default {
  components: {
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    AppSettings,
    PublicPage
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      let key = 'navBar.' + name;
      return this.capitalizeFirstLetter(this.$i18n.t(key));
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  
  data() {
    return {
      appSettings: false,
      publicPage: false,
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      state: window.localStorage.getItem('state') || 'California',
      instance: '',
      cityToDisplay: [],
      isDisplayCity: '',
      city : '',
    };
  },
  created() {
    this.instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city = this.instance.city;
    this.isDisplayCity = RoadNetwork[this.instance.city].isMultiCity;
    if(this.isDisplayCity === true){
      let dynamicCity = Object.keys(RoadNetwork[this.instance.city].city);
      dynamicCity.forEach((key) => {
          this.cityToDisplay.push({'key': key, 'value': key});
          // console.log(cityToDisplay)
      });
      if(window.localStorage.getItem("state") == undefined || window.localStorage.getItem("state") == null){
        this.changeState(this.cityToDisplay[0].value)
      }
    }
  },
  methods: {
    showModalSettings() {
      this.appSettings = true;
    },
    hideAppSettings() {
      this.appSettings = false;
    },
    showModalPublicPage() {
      this.publicPage = true;
    },
    hidePublicPage() {
      this.publicPage = false;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      //let _this = this;
      axios.post('logout', {}).then(response => {
        if (response.data.success) {
          window.localStorage.removeItem("access_token");
          window.localStorage.removeItem("user");
          window.localStorage.removeItem("role");
          window.localStorage.removeItem("instance");
          //_this.$router.push({path: '/login'});
          window.location.href='/login';
        } else {
          
        }
      }).catch(e => {
        console.log("err create::::", e);
      }); 
    },
    changeLanguage (type) {
      let user = JSON.parse(window.localStorage.getItem('user'));
      let _this=this;
      axios.post('user/edit', {uuid: user.uuid, lang: type}).then(response => {
        if (response.data.success) {
          _this.$root.$i18n.locale = type;
          _this.$validator.localize(type);
          let user = JSON.parse(window.localStorage.getItem('user'));
          user.lang=type;
          window.localStorage.setItem('user', JSON.stringify(user));
          window.location.reload();
        }
      }).catch(e => {
        console.log("err create::::", e);
      }); 
    },
    changeState (type) {
      this.state = type;
      window.localStorage.setItem('state', type);
      window.location.reload();
    }
  }
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
.dropdown.nav-item.dropdown {
  margin-top: 2px !important;
}
li.dropdown-language {
  margin-top: 4px !important; 
}
li.lang-item span {
  margin-left: 10px;
}
div.navbar-logo {
  height: 52px;
  width: 250px;
  background-position: center; 
  background-repeat: no-repeat;
  /* background-size: cover; */
  margin-left: 70px;
}

div.navbar-logo-bell, div.navbar-logo-safelane {
  height: 52px;
  width: 250px;
  background-position: left; 
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 70px;
}

@media only screen and (max-width: 768px) {
  div.navbar-logo, div.navbar-logo-bell, div.navbar-logo-safelane {
    width: 150px;
    margin-left: 35px;
  }
}

body:not(white-content) div.navbar-logo {
  background-image: url("../../../public/img/new/roadworks.svg");
}
body.white-content div.navbar-logo {
  background-image: url("../../../public/img/new/roadworks.svg");
}

body:not(white-content) div.navbar-logo-bell {
  background-image: url("../../../public/img/bell_logo.png");
}
body.white-content div.navbar-logo-bell {
  background-image: url("../../../public/img/bell_logo.png");
}

body:not(white-content) div.navbar-logo-safelane {
  background-image: url("../../../public/img/safelane_logo.png");
}
body.white-content div.navbar-logo-safelane {
  background-image: url("../../../public/img/safelane_logo.png");
}

.feedback-dark-icon {
  fill: var(--dark-color) !important;
}
.feedback-light-icon {
  fill: var(--light-color) !important;
}
/*
body:not(white-content) div.navbar-logo {
  background-image: url("../../../public/img/logoLight.png");
}
body.white-content div.navbar-logo {
  background-image: url("../../../public/img/logoDark.png");
} */
</style>