import Vue from 'vue';
import Router from 'vue-router';
import AppLayout from './../app/Layout/AppLayout.vue';
import Starter from './../app/map/MapPage.vue';
import Projects from './../app/projects/ProjectsPage.vue';
import ProjectForm from './../app/projects/ProjectForm.vue';

import Users from './../app/users/UsersPage.vue';
import UserForm from './../app/users/UserForm.vue';

import Archives from './../app/archives/ArchivesPage.vue';
import ArchiveForm from './../app/archives/ArchiveForm.vue';

import AuthLayout from './../auth/AuthLayout.vue';
import AzureAuth from './../auth/AzureAuth.vue';
import Login from './../auth/LoginPage.vue';
import Forget from './../auth/ForgetPage.vue';

import PublicLayout from './../public/PublicLayout.vue';
import Public from './../public/PublicPage.vue';

import Layers from './../app/layers/LayerPage.vue';
import LayersForm from './../app/layers/LayerForm.vue';

import EmailsList from './../app/emails/List.vue';

// new
import ProjectFormNew from './../app/projects/new/ProjectFormNew.vue';


import Feedbacks from './../app/feedback/FeedbacksPage.vue';
import FeedbackForm from './../app/feedback/FeedbackForm.vue';

Vue.use(Router);

var routes =  new Router({
  routes: [{
      path: '/login',
      name: 'Authentication',
      component: AuthLayout,
      children: [
        {
          path: 'login',
          name: 'Login',
          alias: '/login',
          component: Login
        }
      ]
    },      
    {
      path: '/forget',
      name: 'Forget',
      component: AuthLayout,
      children: [
        {
          path: 'forget',
          name: 'Forget',
          alias: '/forget',
          component: Forget
        }
      ]
    }, {
      path: '/public',
      name: 'Public',
      component: PublicLayout,
      children: [
        {
          path: 'public',
          name: 'Public',
          alias: '/public',
          component: Public
        }
      ]
    }, {
      path: '/',
      name: 'map',
      redirect: '/app',
      component: AppLayout,
      children: [
        {
          path: 'app',
          name: 'projects',
          meta: {
            hideContent: true,
            hideFooter: true,
            requiresAuth: true,
            permissions: [
              {
                role: "admin",
                access: true
              }, {
                role: "user",
                access: true
              }, {
                role: "viewer",
                access: true
              }, {
                role: "userRes",
                access: true
              }, {
                role: "viewerRes",
                access: true
              }, {
                role: "adminGis",
                access: true
              }
            ]
          },
          components: { default: Starter }
        },{
          path: 'focus',
          name: 'projects',
          meta: {
            hideContent: true,
            hideFooter: true,
            requiresAuth: true,
            permissions: [
              {
                role: "admin",
                access: true
              }, {
                role: "user",
                access: true
              }, {
                role: "viewer",
                access: true
              }, {
                role: "userRes",
                access: true
              }, {
                role: "viewerRes",
                access: true
              }, {
                role: "adminGis",
                access: true
              }, {
                role: "adminGis",
                access: true
              }
            ]
          },
          components: { default: Starter }
        }
      ]
    }, {
      path: '/projects',
      component: AppLayout,
      redirect: '/projects/list',
      name: 'Projects',
      children: [
        {
          path: 'list',
          name: 'projectsList',
          components: { default: Projects },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: "admin",
                access: true
              }, {
                role: "user",
                access: true
              }, {
                role: "viewer",
                access: true
              }, {
                role: "userRes",
                access: true
              }, {
                role: "viewerRes",
                access: true
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'create',
          name: 'projectCreate',
          components: { default: ProjectForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: true
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'quick/create',
          name: 'projectCreate',
          components: { default: ProjectFormNew },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: true
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'quick/edit',
          name: 'projectEdit',
          components: { default: ProjectFormNew },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'edit',
          name: 'projectEdit',
          components: { default: ProjectForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'view',
          name: 'projectView',
          components: { default: ProjectForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true
              }, {
                role: 'viewer',
                access: true,
              }, {
                role: "userRes",
                access: true,
              }, {
                role: "viewerRes",
                access: true,
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }
      ]
    }, {
      path: '/users',
      component: AppLayout,
      redirect: '/users/list',
      name: 'Users',
      children: [
        {
          path: 'list',
          name: 'usersList',
          components: { default: Users },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'create',
          name: 'userCreate',
          components: { default: UserForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'edit',
          name: 'userEdit',
          components: { default: UserForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }
      ]
    }, {
      path: '/archives',
      component: AppLayout,
      redirect: '/archives/list',
      name: 'Archives',
      children: [
        {
          path: 'list',
          name: 'archivesList',
          components: { default: Archives },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }, {
          path: 'create',
          name: 'archiveCreate',
          components: { default: ArchiveForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }, {
          path: 'edit',
          name: 'archiveEdit',
          components: { default: ArchiveForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }
      ]
    }, {
      path: '/layer',
      component: AppLayout,
      redirect: '/layer/list',
      name: 'Layers',
      children: [
        {
          path: 'list',
          name: 'layerList',
          components: { default: Layers },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: false,
                redirect: '/login'
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }, {
          path: 'create',
          name: 'layerCreate',
          components: { default: LayersForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: false,
                redirect: '/login'
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }, {
          path: 'edit',
          name: 'layerEdit',
          components: { default: LayersForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: false,
                redirect: '/login'
              }, {
                role: 'user',
                access: false,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: false,
                redirect: '/login'
              }, {
                role: "userRes",
                access: false,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: false,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }
      ]
    },
    {
      path: '/emails',
      component: AppLayout,
      name: "Emails",
      redirect: '/emails/list',
      children: [
        {
          path: 'list',
          name: 'emailsList',
          components: { default: EmailsList },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: "admin",
                access: true
              }, {
                role: "user",
                access: true
              }, {
                role: "viewer",
                access: true
              }, {
                role: "userRes",
                access: true
              }, {
                role: "viewerRes",
                access: true
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        }
      ]
    },
    {
      path: '/feedback',
      component: AppLayout,
      children: [
        {
          path: 'create',
          name: 'feedbackCreate',
          components: { default: FeedbackForm },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: true,
                redirect: '/login'
              }, {
                role: "userRes",
                access: true,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: true,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
        {
          path: 'list',
          name: 'feedbacksList',
          components: { default: Feedbacks },
          meta: {
            requiresAuth: true,
            permissions: [
              {
                role: 'admin',
                access: true
              }, {
                role: 'user',
                access: true,
                redirect: '/login'
              }, {
                role: 'viewer',
                access: true,
                redirect: '/login'
              }, {
                role: "userRes",
                access: true,
                redirect: '/login'
              }, {
                role: "viewerRes",
                access: true,
                redirect: '/login'
              }, {
                role: "adminGis",
                access: true
              }
            ]
          }
        },
      ]
    },
    {
      path: '/:access_token(.*)',
      component: AuthLayout,   
      children: [
        {
          path: '/:access_token(.*)',
          name: 'AzureAuth',
          alias: '/:access_token(.*)',
          component: AzureAuth
        }
      ]  
    }, 
  ],
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

routes.beforeEach((to, from, next) => {
  if((to.path != '/login') || (to.path != '/forget')) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (window.localStorage.getItem('access_token')) {
        let permissions = to.meta.permissions;
        let role = window.localStorage.getItem('role');
        let access = false;
        permissions.forEach(function(element) {
          if (element.role===role){
            access = element.access;
          }
        });
        if (access) {
          next();
        } else {
          next('/login');
        }
      } else {
        next('/login');
      }
    } else {
      next();
    }
    /*if (to.matched.some(record => record.meta.requiresAuth) && !window.localStorage.getItem('access_token')) {
      next('login');
    } else {
      next();
    }*/
  } else {
    next();
  }
});

export default routes;