<template>
  <div>
    <div class="loading" v-if="loading">
      <img src="../../../public/img/loading.gif" />
    </div>
    <div v-else>
      <div v-show="conflicts.length==0">
        <base-alert type="info"> {{ $t('projectList.noConflicts') }}</base-alert>
      </div>
      <div class="conflict-list-container" v-show="conflicts.length > 0" style="overflow-x: auto;">
        <base-table :data="conflicts" thead-classes="text-primary project-table-head" tbody-classes="project-table-body">
          <template slot="columns" slot-scope="{ columns }">
            <th width="36px"></th>
            <th width="28%">{{ $t('projectConflict.labelProject') }}</th>
            <th width="10%">{{ $t('projectConflict.labelStepProject') }}</th>
            <th width="36px"></th>
            <th width="28%">{{ $t('projectConflict.labelProjectConflict') }}</th>
            <th width="10%">{{ $t('projectConflict.labelProjectConflictStep') }}</th>
            <th>{{ $t('projectConflict.labelProjectDuration') }}</th>
          </template>      
          <template slot-scope="{ row, index }" >
            <td style="width: 36px;">
              <img v-if="row.conflict1.step=='entrave'" src="../../../public/img/conflict-c3.png" />
              <img v-if="row.conflict1.step=='closing'" src="../../../public/img/conflict-c4.png" />
              <img v-if="row.conflict1.step=='detour'" src="../../../public/img/conflict-c2.png" />
            </td>
            <td style="width: 28%">
              <p class="title" @click="editProject(row.conflict1.uuid, row.conflict1.name)" style="cursor:pointer !important;">{{ row.conflict1.name }}</p>
              <p class="text-muted" v-html="row.conflict1.value"></p>
            </td>
            <td class="border-right" style="width: 10%;">
              <span class="title" v-html="formatType(row.conflict1.step)"></span>
              <span class="title" v-if="row.conflict1.step=='entrave'"  > #{{row.conflict1.entraveIndex}}</span>
              <span class="title" v-if="row.conflict1.step=='detour'"  > #{{row.conflict1.detourIndex}} (Phase #{{row.conflict1.phaseIndex}})</span>
              <span class="title" v-if="row.conflict1.step=='closing'"  > #{{row.conflict1.closeIndex}} (Phase #{{row.conflict1.phaseIndex}})</span>
            </td>
            <td colspan="4" class="container-inconflict">
              <div v-for="(item,index) in row.conflict2" class="content-inconflict">
                <div class="inconflict-img" >
                  <img v-if="item.step=='entrave'" src="../../../public/img/conflict-c3.png" />
                  <img v-if="item.step=='closing'" src="../../../public/img/conflict-c4.png" />
                  <img v-if="item.step=='detour'" src="../../../public/img/conflict-c2.png" />
                </div>
                <div class="inconflict-name">
                  <p class="title" @click="editProject(item.uuid, item.name)" style="cursor: pointer !important;">{{ item.name }}</p>
                  <p class="text-muted" v-html="item.value"></p>
                </div>
                <div class="inconflict-step">
                  <span class="title" v-html="formatType(item.step)"></span>
                  <span class="title" v-if="item.step=='entrave'"> #{{item.entraveIndex}}</span>
                  <span class="title" v-if="item.step=='detour'">  #{{item.detourIndex}} (Phase #{{item.phaseIndex}})</span>
                  <span class="title" v-if="item.step=='closing'"> #{{item.closeIndex}} (Phase #{{item.phaseIndex}})</span>
                </div>
                <div class="inconflict-date">
                  <p v-for="(item1,index1) in item.dates" v-html="$options.filters.formatDate(item1)" class="span-date"></p>
                </div>
              </div>
            </td>
          </template>
        </base-table>
      </div>
    </div>
    <div  slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
      <div v-show="conflicts.length > 0" class="col-lg-6 col-md-6 col-sm-6 conflict-legend">
        <div  style="font-weight: bold; margin-right: 20px;" >{{ $t('mapPage.legende') }}</div>
        <div ><img src="../../../public/img/conflict-c3.png" /> {{ $t('mapPage.conflictEntrave') }}&nbsp;&nbsp;|&nbsp;&nbsp;<img src="../../../public/img/conflict-c4.png" /> {{ $t('mapPage.conflictClosing') }}&nbsp;&nbsp;|&nbsp;&nbsp;<img src="../../../public/img/conflict-c2.png" />{{ $t('mapPage.conflictDetour') }}</div>
      </div>
      <div v-show="conflicts.length > 0" class="paginate-container">
        <p class="card-category"> {{ $t('projectList.pageResultsFrom') }} {{ from + 1 }} {{ $t('projectList.pageResultsTo') }} {{ to }} {{ $t('projectList.pageResultsOn') }} {{ total }}</p>
        <base-pagination
          v-show="conflicts.length > 0"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total">
        </base-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from 'axios';
  import { Table, TableColumn} from 'element-ui';
  import { BasePagination, BaseAlert } from 'src/components';
  import { BaseTable } from '@/components';
  import moment from 'moment';
  import swal from 'sweetalert2';
  
  export default {
    name: 'projects-conflicts',
    props: ['showConflicts'],
    components: {
      BasePagination,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BaseAlert,
      BaseTable
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      isAdmin() {
        return (window.localStorage.getItem('role')==='admin' || window.localStorage.getItem('role')==='adminGis');
      },
      isUser() {
        return window.localStorage.getItem('role')==='user';
      },
      isUserRes() {
        return window.localStorage.getItem('role')==='userRes';
      },
      isViewer() {
        return window.localStorage.getItem('role')==='viewer';
      },
      isViewerRes() {
        return window.localStorage.getItem('role')==='viewerRes';
      }
    },
    filters: {
      formatDate(dates) {
        let val = '';
        if (Array.isArray(dates)) {
          dates.forEach((e) => {
            let date = e.range || e.ranges;
            val = val + moment(date[0]).format('DD-MM-YYYY hh:mm:ss') + ' &xrarr; ' + moment(date[1]).format('DD-MM-YYYY hh:mm:ss') + '</br>';
          });
        } else {
          let date = dates.range || dates.ranges;
          val = val + moment(date[0]).format('DD-MM-YYYY hh:mm:ss') + ' &xrarr; ' + moment(date[1]).format('DD-MM-YYYY hh:mm:ss');
        }
        return val;
      }
    },
    data() {
      return {
        loading: false,
        conflicts: [],
        total: 0,
        pagination: {
          perPage: 5,
          currentPage: 1,
          total: 0
        }
      };
    },
    methods: {
      formatType(value) {
        let types = {
          'entrave': this.$i18n.t('projectConflict.conflictStepEntrave'),
          'closing': this.$i18n.t('projectConflict.conflictStepClosing'),
          'detour': this.$i18n.t('projectConflict.conflictStepDetour')
        };
        return types[value];
      },
      getConflicts() {
        let _this=this;
        let filters={};
        filters.from = (this.pagination.perPage * (this.pagination.currentPage - 1)) + 1;
        filters.limit = this.pagination.perPage;
        _this.loading=true;
        axios.post('project/get_conflicts', filters).then(response => {
          let data = response.data;
          if (data.success) {
            _this.loading=false;
            _this.conflicts = _this.formatData(data.data);
            _this.total = data.total;
          } else {
            if (data.need_auth) {
              _this.$router.push({path: '/login'});
            } else {
              _this.$notify({
                message:  this.$i18n.t('serverReply.errorProject'),
                timeout: 10000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch(() => {
          _this.$notify({
            message:  this.$i18n.t('serverReply.errorProject'),
            timeout: 10000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        });
      },
      formatData(conflicts) {
        conflicts.forEach((e)=> {
          e.conflict2=[e.conflict2];
        });
        let data=[];
        conflicts.forEach((conflict) => {
          let len = data.length;
          if (len==0) {
            data.push(conflict);
          } else {
            if (data[len-1].conflict1.step==conflict.conflict1.step) {
              let element = data[len-1];
              if ((conflict.conflict1.step=='entrave') && (data[len-1].conflict1.entraveId==conflict.conflict1.entraveId)) {
                element.conflict2.push(conflict.conflict2[0]);
                element.rowspan=element.conflict2.length;
                data[len-1]=element;
              }
              else if ((conflict.conflict1.step=='closing') && (data[len-1].conflict1.closingId==conflict.conflict1.closingId)) {
                element.conflict2.push(conflict.conflict2[0]);
                element.rowspan=element.conflict2.length;
                data[len-1]=element;
              }
              else if ((conflict.conflict1.step=='detour') && (data[len-1].conflict1.detourId==conflict.conflict1.detourId)) {
                element.conflict2.push(conflict.conflict2[0]);
                element.rowspan=element.conflict2.length;
                data[len-1]=element;
              } else {
                data.push(conflict);
              }
            } else {
              data.push(conflict);
            }
          }
        });
        return data;
      },
      editProject(uuid, name) {
        let title = this.$i18n.t('projectList.titleProjectEdit')+ name;
        if (this.$i18n.locale=='en') {
          title=title+'?';
        } else {
          title=title+' ?';
        }
        swal({
          title: title,
          //text:  this.$i18n.t('projectList.textProjectEdit'),
          text: '',
          type: 'warning',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: this.$i18n.t('projectList.textConfirmProjectEdit'),
          cancelButtonText: this.$i18n.t('projectList.textCancelProjectEdit'),
          buttonsStyling: false
        }).then((resp) => {
          if (resp.value) {
            window.location.href='/#/projects/edit?uuid='+uuid;
          }
        });
      }
    },
    watch: {
      'pagination.currentPage'() {
        this.getConflicts();
      },
      'showConflicts'(val) {
        if (val) {
          this.getConflicts();
        }
      }
    }
  };
</script>
<style>
  div.conflict-list-container span.title {
    cursor:  default;
  }
  td.border-right {
    /*border-right: 1px solid #3d3f51 !important;*/
    border-right: none !important;
  }
  td.padding-left {
    padding-left: 15px !important;
  }
  .span-date {
    border: 1px solid #e8e8eb;
    padding: 10px;
    border-radius: 5px;
    background-color: #FFF;
    margin-bottom: 10px;
    color: #746c7d !important;
  }
  div.conflict-legend {
    margin-top: 20px;
    margin-left: -20px;
    display: inherit;
  }
  div.conflict-legend div {
    margin: 0;
    padding: 0;
  }
  div.conflict-legend div img{
    padding-right: 5px;
    width: 24px;
  }
  td.container-inconflict {
    padding: 12px 7px;
    vertical-align: middle;
  }
  div.content-inconflict {
    display: flex;
  }
  div.inconflict-img {
    width: 36px;
    margin-top: 20px;
  }
  div.inconflict-name {
    width: 45%;
  }
  div.inconflict-step {
    width: 20%;
    margin-top: 20px;
    padding-left: 20px;
  }
  div.inconflict-date {
    margin-left: -10px;
    padding-top: 10px;
  }
</style>