<template>
  <div v-if="send" class="loading">
    <img src="../../../../public/img/loading.gif" />
  </div>
  <div v-else class="project-form-create row d-flex justify-content-center">
    <div class="col-md-12">
      <simple-wizard nextButtonText="" prevButtonText="" :finishButtonText="$i18n.t('quickCreate.finishButton')" isCustomFinish="true">
        <template slot="header">
          <h6 class="card-title"></h6>
        </template>
        <wizard-tab :before-change="() => validateStep('general')" >
          <template slot="label">
            <i class="tim-icons icon-alert-circle-exc"></i>
            <p>{{ $t('projectForm.entravesTitle') }}</p>
          </template>
          <collapse class="collapse-project">
            <collapse-item class="collapse-quick-create"  :title="$i18n.t('quickCreate.collapseTitle.title1') + ' *'" name="1" :id="1" >
              <div>
                <second-step ref="step2" v-bind:uuid="wizardModel.uuid" v-bind:entraves="wizardModel.entraves" v-bind:mode="mode" @on-validated="onStepValidated" v-bind:layer="wizardModel.entraves.layer" v-bind:statusOfUser="isViewer"  v-on:onChangeLayer="onChangeLayer($event)"></second-step>
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-6">
                    <base-input name="name" required v-bind:label="$t('projectForm.labelProjectName')"
                      v-bind:placeholder="$t('projectForm.placeholderProjectName')"
                      v-model="wizardModel.informations.name"
                      v-validate="modelValidations.name"
                      :error="getError('name')">
                    </base-input>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 alphanumeric">
                    <base-input name="reference" v-bind:label="$t('projectForm.labelProjectRef')"
                      v-bind:placeholder="$t('projectForm.placeholderProjectRef')"
                      v-model="wizardModel.informations.reference">
                    </base-input>
                  </div>
                </div>
              </div>
            </collapse-item>


            <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title2')" :id="2" name="2" isClose="true">
                <div>
                  <div class="row margin-top-10">
                    <div class="col-md-12 col-sm-12">
                      <label>{{ $t('quickCreate.description.labelNotes') }}</label>
                      <span v-if="statusOfUser"  v-html="wizardModel.informations.notes"></span>
                      <vue-pell-editor 
                          v-else          
                          v-model="wizardModel.informations.notes"
                          :actions="editorOptions"
                          :placeholder="editorPlaceholderNotes"
                          :style-with-css="false"
                          :classes="editorClasses"
                          default-paragraph-separator="p"/>
                    </div>
                    <div>
                      

                    </div>
                    <div class="col-md-12 col-sm-12 mt-1">
                      <label>{{ $t('projectForm.labelComment') }}</label><label v-if="city == 'sjsr'"> * </label> 
                      <base-button type="info" link size="sm" @click="copyFromCommunication">
                          <i class="tim-icons icon-single-copy-04"></i> 
                          <label style="font-size: 12px; margin-bottom:0;">
                            {{ $t('quickCreate.description.copyButton') }}
                          </label> 
                      </base-button>

                      <span v-if="statusOfUser"  v-html="wizardModel.informations.comment"></span>
                      <vue-pell-editor 
                          v-else          
                          v-model="wizardModel.informations.comment"
                          :actions="editorOptions"
                          :placeholder="editorPlaceholder"
                          :style-with-css="false"
                          :classes="editorClasses"
                          default-paragraph-separator="p"/>
                    </div>
                  </div>
                </div>
              </collapse-item>

              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title3')" :id="3" name="3" isClose="true">
                <StakeholderComponent :informations="wizardModel.informations" />
              </collapse-item>

              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title5') + ' *'" :id="5" name="5" isClose="true">
                <div>
                  <fieldset class="fieldset-group mb-2">
                    <legend class="h5 mb-0 pl-1 fieldset-title">{{ $t('quickCreate.collapseTitle.title4') }} *</legend>
                    <div class="row mb-1">
                      
                      <div v-show="filters.serviceInfra.show" class="col-sm-6 col-md-6 col-lg-6">
                        <label>{{ $t('projectForm.workingLabel2') }}</label>
                        <el-select multiple
                            class="select-primary"
                            name="serviceInfra"
                            @input="changeWorkCategory()"
                            :disabled="statusOfUser"
                            v-bind:placeholder="$t('projectForm.workingLabel2')"
                            v-model="wizardModel.informations.serviceInfra">
                          <el-option
                            v-for="option in filters.serviceInfra.value"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.label">
                          </el-option>
                        </el-select>
                        <label v-if="modelValidations.departmentError" class="error">
                          {{ $t('projectForm.requiredField') }}
                        </label>
                      </div>
                      <div v-show="filters.servicePublic.show" class="col-sm-6 col-md-6 col-lg-6">
                        <label>{{ $t('projectForm.workingLabel3') }}</label>
                        <el-select multiple
                            @input="changeWorkCategory()"
                            class="select-primary"
                            :disabled="statusOfUser"
                            name="servicePublic"
                            v-bind:placeholder="$t('projectForm.workingLabel3')"
                            v-model="wizardModel.informations.servicePublic">
                          <el-option
                            v-for="option in filters.servicePublic.value"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.label">
                          </el-option>
                        </el-select>
                        <label v-if="modelValidations.departmentError" class="error">
                          {{ $t('projectForm.requiredField') }}
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <div class="row">
                  <div v-if='filters.step.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ $t('projectForm.labelProjectStep') }} *</label>
                    <el-select
                      class="select-primary select-inline"
                      name="step"
                      size="large"
                      clearable
                      :disabled="statusOfUser"
                      v-validate="modelValidations.step"
                      v-bind:placeholder="$t('projectForm.placeholderProjectStep')"
                      v-model="wizardModel.informations.step">
                      <el-option
                        v-for="option in filters.step.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                    <label v-if="errors.has('step')" class="error">
                      {{ $t('projectForm.requiredField') }}
                    </label>
                  </div>
                  <div v-if='filters.entity.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ $t('projectForm.labelProjectEntity') }}</label>
                    <el-select  multiple
                      class="select-primary"
                      name="entity"
                      size="large"
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderProjectEntity')"
                      v-model="wizardModel.informations.entity">
                      <el-option
                        v-for="option in filters.entity.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.status.show' class="col-sm-6 col-md-6 col-lg-6">
                    <!--<label>{{ $t('projectForm.labelProjectStatus') }}</label>-->
                    <label>{{ filters.status.title.label }}</label>
                    <el-select
                      class="select-primary select-inline"
                      name="status"
                      size="large"
                      clearable
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderProjectStatus')"
                      v-model="wizardModel.informations.status">
                      <el-option
                        v-for="option in filters.status.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.workType1.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.workType1.title.label }} </label> <label v-if="city == 'sjsr'"> * </label>
                      <el-select multiple
                        class="select-primary"
                        name="workType1"
                        :disabled="statusOfUser"
                        v-bind:placeholder="$t('projectForm.placeholderProjectworkType1')"
                        v-model="wizardModel.informations.workType1">
                      <el-option
                        v-for="option in filters.workType1.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                    


                  </div>
                  <div v-if='filters.workCategory.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.workCategory.title.label }}*</label>
                    <el-select multiple
                      v-validate="modelValidations.workCategory"
                      class="select-primary"
                      name="workCategory"
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderProjectworkCategory')"
                      v-model="wizardModel.informations.workCategory">
                    <el-option
                      v-for="option in filters.workCategory.value"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                      </el-option>
                    </el-select>
                    <label v-if="errors.has('workCategory')" class="error">
                      {{ $t('projectForm.requiredField') }}
                    </label>
                  </div>
                  <div v-if='filters.district.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.district.title.label }}</label>
                      <el-select multiple
                        class="select-primary"
                        name="district"
                        :disabled="statusOfUser"
                        v-bind:placeholder="$t('projectForm.placeholderProjectDistrict')"
                        v-model="wizardModel.informations.district">
                      <el-option
                        v-for="option in filters.district.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.workType.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.workType.title.label }} </label>
                    <el-select  multiple
                      class="select-primary"
                      size="large"
                      :disabled="statusOfUser"
                      v-bind:placeholder="filters.workType.title.label"
                      v-model="wizardModel.informations.workType">
                      <el-option
                        v-for="option in filters.workType.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.city.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ $t('projectForm.labelProjectCity') }}</label>
                    <el-select multiple
                      class="select-primary"
                      size="large"
                      name="city" 
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderProjectCity')"
                      v-model="wizardModel.informations.city">
                      <el-option
                        v-for="option in filters.city.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='!!filters.departments && filters.departments.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.departments.title.label }}*</label>
                    <el-select 
                      v-validate="modelValidations.departments"
                      class="select-primary"
                      size="large"
                      name="departments"
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderProjectWorkType')"
                      v-model="wizardModel.informations.departments">
                      <el-option
                        v-for="option in filters.departments.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                    <label v-if="errors.has('departments')" class="error">
                      {{ $t('projectForm.requiredField') }}
                    </label>
                  </div>
                  <div v-if='filters.sector.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.sector.title.label }}* </label>
                    <el-select  multiple
                      v-validate="modelValidations.sector"
                      class="select-primary"
                      size="large"
                      name="sector"
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderSector')"
                      v-model="wizardModel.informations.sector">
                      <el-option
                        v-for="option in filters.sector.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                    <label v-if="errors.has('sector')" class="error">
                      {{ $t('projectForm.requiredField') }}
                    </label>
                  </div>
                  <div v-if='filters.distp.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.distp.title.label }} </label>
                    <el-select
                      class="select-primary"
                      size="large"
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderDistp')"
                      v-model="wizardModel.informations.distp">
                      <el-option
                        v-for="option in filters.distp.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  <div v-if='filters.dista.show' class="col-sm-6 col-md-6 col-lg-6">
                    <label>{{ filters.dista.title.label }} </label>
                    <el-select  multiple
                      class="select-primary"
                      size="large"
                      clearable
                      :disabled="statusOfUser"
                      v-bind:placeholder="$t('projectForm.placeholderDista')"
                      v-model="wizardModel.informations.dista">
                      <el-option
                        v-for="option in filters.dista.value"
                        class="select-primary"
                        :value="option.value"
                        :label="option.label"
                        :key="option.label">
                      </el-option>
                    </el-select>
                  </div>
                  </div>
                </div>
              </collapse-item>

              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title6')" :id="6" name="6" isClose="true">
                <div>
                  <div v-if="filters.trafficImpact.show" class="row">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <label>{{ filters.trafficImpact.title.label }}</label>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <label>{{ filters.trafficImpact.title1.label }}</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row" v-for="(item,index) in wizardModel.informations.trafficImpact.value" v-bind:index="index" v-bind:item="item" v-bind:key="index">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <base-checkbox v-model="item.status" @input="check('trafficImpact', item.value, $event)" :checked="item.status" :disabled="statusOfUser"> {{ formatLabel(item) }}  </base-checkbox>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <base-input name="item.value" v-model="item.content" :disabled="!item.status" ></base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="filters.portableWater.show" class="row margin-top-20">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <label>{{ filters.portableWater.title.label }}</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row" v-for="(item,index) in wizardModel.informations.portableWater.value" v-bind:index="index" v-bind:item="item" v-bind:key="index">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <base-checkbox v-model="item.status" @input="check('portableWater', item.value, $event)" :checked="item.status" :disabled="statusOfUser"> {{ formatLabel(item) }} </base-checkbox>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <base-input name="item.value" v-model="item.content" :disabled="!item.status" ></base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="filters.passanger.show" class="row margin-top-20">
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <label>{{ filters.passanger.title.label }}</label>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-6">
                      <label>{{ filters.passanger.title1.label }}</label>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row" v-for="(item,index) in wizardModel.informations.passanger.value" v-bind:index="index" v-bind:item="item" v-bind:key="index">
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <base-checkbox v-model="item.status" @input="check('passanger', item.value, $event)" :checked="item.status" :disabled="statusOfUser"> {{ formatLabel(item) }} </base-checkbox>
                        </div>
                        <div class="col-sm-6 col-md-6 col-lg-6">
                          <base-input name="item.value" v-model="item.content" :disabled="!item.status" ></base-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>

              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title7')" :id="7" name="7" isClose="true">
                <div>
                  <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.show" class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <label>{{ $t('projectForm.labelProjectCommunication1') }}</label>
                      <div class="row margin-left--30">
                        <div class="col-sm-12 col-md-12 col-lg-12" style="display: inherit;">
                          <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.internalCommunication.show" class="col-sm-4 col-md-4 col-lg-4">
                            <base-checkbox v-model="wizardModel.informations.internalCommunication" :checked="wizardModel.informations.internalCommunication" :disabled="statusOfUser">{{ $t('projectForm.labelInternalCommunication') }}</base-checkbox>
                          </div>
                          <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.citizenOpinions.show" class="col-sm-4 col-md-4 col-lg-4">
                            <base-checkbox v-model="wizardModel.informations.citizenOpinions" :checked="wizardModel.informations.citizenOpinions" :disabled="statusOfUser">{{ $t('projectForm.labelProjectcitizenOpinions') }}</base-checkbox>
                          </div>
                          <div v-if="features.communicationToolsOnProjectInformationPageWhileAddingOrUpdating.externalCommunication.show" class="col-sm-4 col-md-4 col-lg-4">
                            <base-checkbox v-model="wizardModel.informations.externalCommunication" :checked="wizardModel.informations.externalCommunication" :disabled="statusOfUser">{{ $t('projectForm.labelExternalCommunication') }}</base-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>

              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title8')" :id="8" name="8" isClose="true">
                <div>
                  <div class="row" v-if="features.publishProjectToPublicMap.show">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row margin-left--30">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                          <div class="col-sm-12">
                            <base-checkbox   
                                v-model="wizardModel.informations.typeMap.map" 
                                @input="onParentCheckboxChange"    
                                :checked="wizardModel.informations.typeMap.map"
                                :disabled="statusOfUser">
                                {{ $t('quickCreate.publish.titlePublicMap') }}
                            <button class="btn-no-border " @click="toggleCollapse"  :class="{visible: isCollapseActive}"><i class="tim-icons icon-minimal-down"></i> </button>
                            </base-checkbox>
                            <div class="row collapse-content " v-bind:class="{visible: isCollapseActive}">
                              <div class="col-sm-3 col-md-3 col-lg-3">
                                <base-checkbox v-if="features.publishProjectToPublicMap.publishProjectPhase.show" v-model="wizardModel.informations.publicMapWork" :checked="wizardModel.informations.publicMapWork" :disabled="statusOfUser">{{ $t('quickCreate.publish.labelPublicMapWork') }}</base-checkbox>
                              </div>
                              <div class="col-sm-3 col-md-3 col-lg-3">
                                <base-checkbox v-if="features.publishProjectToPublicMap.publishProjectWorkNotice.show" v-model="wizardModel.informations.publishReport" :checked="wizardModel.informations.publishReport" :disabled="statusOfUser">{{ $t('quickCreate.publish.labelProjectPublicAvis') }}</base-checkbox>
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-4 col-md-4 col-lg-4">
                            <base-checkbox v-if="features.publishProjectToPublicMap.publishProjectToWaze.show" class="waze-checkebox" v-model="wizardModel.informations.typeMap.waze" :checked="wizardModel.informations.typeMap.waze">{{ $t('projectForm.labelProjectMapType1') }}</base-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </collapse-item>
              
              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title9')" :id="9" name="9"  isClose="true">
                <div>
                  <div class="upload-box">
                    <button class="btn-no-border upload-btn" @click="modals.modalAttach = true"  >
                      <i class="tim-icons icon-cloud-upload-94"></i> 
                      <div class="upload-content">
                        <span>{{ $t('quickCreate.document.uploadLabel') }}</span>
                        <span>{{ $t('quickCreate.document.uploadLabel2') }}</span>
                      </div>
                    </button>
                  </div>

                 
                   <modal :show.sync="modals.modalAttach" modal-classes="modal-dialog-centered" :scrollToBottom="false" v-on:hideModal="modals.modalAttach = false">
                    <template slot="header">
                        <h5 class="modal-title" id="exampleModalLabel"></h5>
                    </template>
                    <template slot="close-button">
                      <button type="button" class="close" @click="modals.modalAttach = false" data-dismiss="modal" aria-label="Close"><i class="tim-icons icon-simple-remove"></i></button>
                    </template>

                    <div class="padding-model">
                      <ArchiveForm :projectId="this.$route.query.uuid" :modalStatus="modals.modalAttach" @update="updateModal" :archiveData="archiveToBeUpdatedData" :archives="archives">
                      </ArchiveForm>
                    </div>
                  </modal> 
                </div>
              </collapse-item>

              <collapse-item class="collapse-quick-create" :title="$i18n.t('quickCreate.collapseTitle.title10')" :id="10" name="10"  isClose="true">
                <div>
                  <div class="row">
                    <div class="col-md-6">
                      <base-checkbox inline class=" checkbox-advance " 
                        @input="onAdvanceDetour"  
                        :checked="wizardModel.projectType.advanceOptions.detour"
                        :class="{visible: wizardModel.projectType.advanceOptions.detour}" 
                        v-model="wizardModel.projectType.advanceOptions.detour">
                          <h4 class="checkbox-advance-title">{{ $t('quickCreate.advance.detourTitle') }}</h4>
                          <!-- <span class="checkbox-advance-description">{{ $t('quickCreate.advance.detourDesc') }}</span> -->
                      </base-checkbox>
                    </div>
                  </div>
                </div>
              </collapse-item>

           
          </collapse>
        </wizard-tab>
        <!-- <wizard-tab >
          <template slot="label">
            <i class="tim-icons icon-pin"></i>
            <p>{{ $t('projectForm.closingTitle') }}</p>
          </template>
          <third-step ref="step3" v-bind:uuid="wizardModel.uuid" v-bind:entraves="wizardModel.entraves.entraves" v-bind:layer="wizardModel.entraves.layer" v-bind:phases="wizardModel.phases" v-bind:sdate="sdate" v-bind:edate="edate" v-bind:perimeter="wizardModel.entraves.perimeter" v-bind:mode="mode"  v-bind:statusOfUser="isViewer"></third-step>
        </wizard-tab> -->

        <wizard-tab v-if="wizardModel.projectType.advanceOptions.detour" :before-change="() => validateStep('step4')">
          <template slot="label">
            <i class="tim-icons icon-compass-05"></i>
            <p>{{ $t('projectForm.detourTitle') }}</p>
          </template>
          <four-step ref="step4" v-bind:uuid="wizardModel.uuid" v-bind:sdate="sdate" v-bind:edate="edate" v-bind:layer="wizardModel.entraves.layer" v-bind:phases="wizardModel.phases" v-bind:perimeter="wizardModel.entraves.perimeter" v-bind:mode="mode" @on-validated="onStepValidated" v-bind:statusOfUser="isViewer"></four-step>
        </wizard-tab>
        

      </simple-wizard>
    </div>
  </div>
</template>


<script>

import features from './../../shared/features';
import { uuid } from 'vue-uuid';
import { SimpleWizard, WizardTab } from 'src/components';
import { Collapse, CollapseItem } from 'src/components'


// entrave import
import { TimeSelect, DatePicker } from 'element-ui';

import {
  BaseSwitch,
  Slider
} from 'src/components/index';

import EntraveComponent from './../EntraveComponent.vue';
import ModalMapComponent from './../../map/ModalMapComponent.vue';

// Information Import
import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
Vue.use(VuePellEditor);
import {Table, TableColumn} from 'element-ui'
Vue.use(Table)
Vue.use(TableColumn)
import tags from './../../shared/filters';
import { Select, Option,Input } from 'element-ui';
import { BaseCheckbox} from 'src/components/index';
import {Modal} from 'src/components';
import ArchiveForm from './../../archives/ArchiveForm.vue';
import { ArchivesTable} from 'src/components/index';
import _ from 'lodash';
import axios from 'axios';
import LoadProjects from './../../shared/MapLoadProjects.js';
import ThirdStep from './../ClosingStep.vue';
import FourStep from './../DetourStep.vue';
import { loadModules } from 'esri-loader';

import SecondStep  from '../Entraves.vue';

import StakeholderComponent from './StakeholderComponent.vue';

export default {
  data() {
    return {
      sdate: '',
      edate: '',
      wizardModel: {
        informations: {
          name: '',
          comment: '',
          notes: '',
          inchargeof: '',
          phone: '',
          email: '',
          role: '',
          agents: [],
          district: '',
          city: '',
          entity: [],
          step: '',
          status: '',
          workType: [],
          serviceInfra: '',
          servicePublic: '',
          workType1: [],
          workCategory: [],
          sector: [],
          dista: [],
          distp: '',
          publish: false,
          publishReport: false,
          citizenOpinions: false,
          notification: false,
          internalCommunication: false,
          externalCommunication: false,
          publicWork: false,
          publicMap: false,
          publicMapWork: false,
          typeMap: {
            waze: false,
            map: false
          },
          passanger: [],
          trafficImpact: [],
          portableWater: [],
          documents: {
            allowed: false,
            plan: false,
            other: ''
          },
          typeProject: 'linear',
          requestDate: '',
          authorizeDate: '',
          cost: '',
          departments: []
        },
        entraves: {
          date: '',
          perimeter: 20,
          entraves: [{
            selected: {
              path: [],
              startPoint: null,
              endPoint: null,
              streetsTrack: []
            },
            type: 'linear',
            value: '',
            label: '',
            name: '',
            showError: false,
            entraveId: uuid.v1(),
          }],
          layer: 'roadworks',
          validateEntraves: false
        },
        phases: [],
        projectType:{
          isQuick: true,
          isDraft: true,
          isPublished: false,
          advanceOptions: {
            detour: false,
            phaseAndDetour: false
          },
        }
      },
      archives: [],
      name: '',
      send: false,
      mode: 'create',
      tags: null,
      loading: false,
      city: '',
      features: [],
      selectedEntraveIndex: 0,
      selectedEntrave: null,
      modalMap: false,
      editorOptions: [
        'bold',
        'underline', {
            name: 'italic',
            result: () => window.pell.exec('italic')
        }, 
        'heading1',
        'heading2',
        'paragraph',
        'strikethrough',
        'olist',
        'ulist',
        'code',
        'line'
      ],
      editorPlaceholder: this.$i18n.t('quickCreate.description.placeholderComment'),
      editorPlaceholderNotes: this.$i18n.t('quickCreate.description.placeholderNotes'),
      editorClasses: {
          actionbar: 'pell-actionbar custom-actionbar',
          button: 'pell-button',
          content: 'pell-content',
          selected: 'pell-button-selected'
      },
      modals:{
        modalAttach: false
      },
      archiveToBeUpdatedData: null,
      authorTable:{
        isAddingRow : false,
        editingRowIndex: false,
        newRowData:{
            name: '',
            role: '',
            phone: '',
            email: '',
            organization: ''
        },
        editingRowData: {},
      },
      filters: [],
      isCollapseActive : false,
      componentName:'InformationStep',
      projectForm:{
        isRecordSaved: false,
        isEditMode: false,
        isSaving: false,
        saveError: null
      },
      modelValidations: {
        date: {
          required: true
        },
        name: {
          required: true
        },
        entrave:{
          required: true
        },
        serviceInfra:{
          required: true
        },
        servicePublic:{
          required: true
        },
        step:{
          required: true
        },
        workCategory:{
          required: true
        },
        sector:{
          required: true
        },
        departments: {
          required: true
        },
        departmentError: true,
      },
      isFormatting: false,
      isFinish: false,
    };
  },
  components: {
    SimpleWizard,
    WizardTab,
    Collapse,
    CollapseItem,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    Slider,
    BaseSwitch,
    EntraveComponent,
    ModalMapComponent,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Option.name]: Option,
    [Select.name]: Select,
    [Input.name]: Input,
    BaseCheckbox,
    Modal,
    ArchiveForm,
    ArchivesTable,
    ThirdStep,
    FourStep,
    SecondStep,
    StakeholderComponent
  },
  watch:{
    wizardModel: {
      deep: true,
      handler() {
        if (!this.isFormatting) {
          this.debouncedSave();
        }
      }
    },
  },
  computed: {
    calculatedDate: function() {
      if (this.wizardModel.entraves.date && this.wizardModel.entraves.date.length==2) {
        return 'true';
      } else {
        return '';
      }
    },
    
  },
  created() {
    let instance = JSON.parse(window.localStorage.getItem('instance'));
    this.city = instance.city;
    this.filters = tags[this.city];
    this.features = features[this.city];
    this.getProject();
    this.debouncedSave = _.debounce(this.autoSave, 2000); // Adjust the delay as needed
  },
  mounted() {
    let _this = this;
    let user = JSON.parse(window.localStorage.getItem('user'));
    let keys = Object.keys(_this.filters);
    keys.forEach((key) => {
      _this.filters[key].value.forEach((element) => {
        if (user.lang=='fr') {
          if (element.label_fr) {
            element.label=element.label_fr;
          }
        } else {
          if (element.label_en) {
            element.label=element.label_en;
          }
        }
      });
      let e = _this.filters[key];
      if (user.lang=='fr') {
        if (e.title && e.title.label_fr) {
          e.title.label=e.title.label_fr;
        }
        if (e.title1 && e.title1.label_fr) {
          e.title1.label=e.title1.label_fr;
        }
      } else {
        if (e.title && e.title.label_en) {
          e.title.label=e.title.label_en;
        }
        if (e.title1 && e.title1.label_en) {
          e.title1.label=e.title1.label_en;
        }
      }
    });
    if (_this.filters.passanger.show) _this.wizardModel.informations.passanger = _this.filters.passanger;
    if (_this.filters.portableWater.show) _this.wizardModel.informations.portableWater = _this.filters.portableWater;
    if (_this.filters.trafficImpact.show) _this.wizardModel.informations.trafficImpact = _this.filters.trafficImpact;
   
  },
  methods: {

    check(type, status, target) {
      let filter;
      if (type==='passanger') {
        filter=this.wizardModel.informations.passanger.value;
      } else if (type==='portableWater') {
        filter=this.wizardModel.informations.portableWater.value;
      } else if (type==='trafficImpact') {
        filter=this.wizardModel.informations.trafficImpact.value;
      }
      filter.forEach(elem => {
        if (elem.value == status && !target) { 
          elem.content='';
        }
      });
    },

    formatLabel(item) {
      let user = JSON.parse(window.localStorage.getItem('user'));
      if (user.lang=='fr'){
        return item.label_fr;
      } else {
        return item.label_en;
      }
    },

    toggleCollapse(){
      this.isCollapseActive = !this.isCollapseActive;
    },

    updateModal(v, archive){
      this.archiveToBeUpdatedData = archive
      this.modals.modalAttach = v;
    },

    async autoSave(){
      let _this = this;
      if(!_this.isFinish){
        this.wizardModel.projectType.isPublished = false;
        this.wizardModel.projectType.isDraft = true;
      }
      var projectData = {..._this.wizardModel};
      this.isFormatting = true;
      projectData =await this.formatProject(projectData);
      this.isFormatting = false;
      if(_this.wizardModel.entraves.date != '' ){
        _this.projectForm.isSaving = true;
        _this.projectForm.saveError = null;
        if (this.projectForm.isRecordSaved) {
          // Update existing record
          await axios.post(`/project/edit`, projectData)
            .then(response => {
              this.handleAutoSave(response);
            })
            .catch(error => {
              this.handleError(error);
            });
        } else {
          // Create new record
          await axios.post('/project/create', projectData)
            .then(response => {
              this.projectForm.isRecordSaved = true;
              this.projectForm.isEditMode = true; // Switch to edit mode after first save
              this.wizardModel.uuid = response.data.uuid; // Assuming the response includes the new ID
              this.handleAutoSave(response);
            })
            .catch(error => {
              this.handleError(error);
            });
        }
      }


    },
    formatProject(project){
      let _this = this;
      var formatted = project;
      var entraves, phases = null;
      entraves = project.entraves.entraves.map(function(entrave){
        return _this.formatEntrave(entrave);
      });
      
      formatted.entraves.entraves = entraves;
      phases = project.phases.map(function(phase){
        var formattedP = phase;
        var closings = formattedP.closing.map(function(closing){
          var formattedC = _this.formatClosing(closing);
          return formattedC;
        });
        formattedP.closing = closings;
        return formattedP;
      });
      return formatted;
    },
    formatEntrave(entrave){
      let _this = this;
      var formatted = entrave;
      var selected = entrave.selected;
      selected.path = entrave.selected.path.map(function(object){
        var formattedObj = {
          geometry   : _this.formatGeometry(object.geometry),
          attributes : object.attributes,
        };
        if (object.suggest !==  null && object.suggest !== undefined ) {formattedObj.suggest = object.suggest;}
        if (object.segment) {formattedObj.segment = { attributes : object.segment.attributes, geometry : _this.formatGeometry(object.segment.geometry)};}
        return formattedObj;
      });
      if(entrave.selected.startPoint) {selected.startPoint = _this.formatGeometry(entrave.selected.startPoint);}
      if(entrave.selected.endPoint) {selected.endPoint   = _this.formatGeometry(entrave.selected.endPoint);}
      formatted.selected = selected;
      return formatted;
    },
    
    formatClosing(closing){
      let _this = this;
      var formatted = closing;
      var selected  = closing.selected;
      var detours;
      if (closing.selected.path) {
        selected.path = closing.selected.path.map(function(object){
          var formattedObj = {
            geometry   : _this.formatGeometry(object.geometry),
            attributes : object.attributes,
          };
          if (object.suggest !==  null && object.suggest !== undefined ) {formattedObj.suggest = object.suggest;}
          if (object.segment) {formattedObj.segment = { attributes : object.segment.attributes, geometry : _this.formatGeometry(object.segment.geometry)};}
          return formattedObj;
        });
      }
      if(closing.selected.startPoint){selected.startPoint = _this.formatGeometry(closing.selected.startPoint);}
      if(closing.selected.endPoint){selected.endPoint = _this.formatGeometry(closing.selected.endPoint);}
      formatted.selected= selected;
      
      detours = closing.detours.map(function(detour){
        return _this.formatDetour(detour);
      });
      formatted.detours = detours;
      return formatted;
    },
    
    formatDetour(detour){
      let _this = this;
      var formatted = detour;
      var selected = detour.selected;
      if (detour.selected.path){
        selected.path = detour.selected.path.map(function(object){
          var formattedObj = {
            geometry : _this.formatGeometry(object.geometry),
            attributes : object.attributes
          };
          if (object.suggest !==  null && object.suggest !== undefined ) {formattedObj.suggest = object.suggest;}
          if (object.segment) {formattedObj.segment = { attributes : object.segment.attributes, geometry : _this.formatGeometry(object.segment.geometry)};}
          
          return formattedObj;
        });
      }
      if(detour.selected.startPoint){selected.startPoint = _this.formatGeometry(detour.selected.startPoint);}
      if(detour.selected.endPoint){selected.endPoint = _this.formatGeometry(detour.selected.endPoint);}
      formatted.selected = selected;
      return formatted;
    },
    
    formatGeometry(geometry){
      var formatted;
      if(geometry.type === "polyline"){
        formatted = {
          type  : geometry.type,
          paths : geometry.paths,
          hasM  : geometry.hasM,
          hasZ  : geometry.hasZ,
          spatialReference : geometry.spatialReference
        };
      }else if(geometry.type === "point"){
        formatted = {
          type : geometry.type,
          latitude  : geometry.latitude,
          longitude : geometry.longitude,
          x : geometry.x,
          y : geometry.y,
          spatialReference : geometry.spatialReference,
          hasM : geometry.hasM,
          hasZ : geometry.hasZ
        };
      }
      return formatted;
    },
    handleAutoSave(response) {
      this.isSaving = false;
      // Handle success (e.g., show success notification)
      if(this.isFinish){
        this.$notify({
          message: this.$i18n.t('quickCreate.message.handleSuccess'),
          timeout: 2000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        });
      }else{
        this.$notify({
          message: this.$i18n.t('quickCreate.message.handleAutoSave'),
          timeout: 2000,
          icon: 'tim-icons icon-bell-55',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        });
      }
    },
    handleSuccess(response) {
      this.isSaving = false;
      this.isFinish = true;
    },
    handleError(error) {
      this.isSaving = false;
      this.saveError = 'Error occurred while saving';
      // Handle error (e.g., show error notification)
      this.$notify({
        message: this.$i18n.t('quickCreate.message.handleError'),
        timeout: 3000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'danger'
      });
    },
    getProject(){
      let _this = this;
      _this.tags = tags[_this.city];
      
      if (_this.$route.query && _this.$route.query.uuid) {
        _this.mode='edit';
        _this.projectForm.isRecordSaved = true;
        _this.wizardModel.uuid=_this.$route.query.uuid;
        axios.get('project/edit?uuid='+_this.wizardModel.uuid).then(response => {
          let data = response.data;

          if (data.success) {
            data.archives.forEach(archive => {
              archive.publishedLabel = archive.published ? '<i class="tim-icons icon-check-2 success"></i>':'<i class="tim-icons icon-simple-remove"></i>';
              archive.fTags = '';
              archive.tags.split(',').forEach((tag) => {
                  archive.fTags += '<span class="badge badge-info">' + tag + '</span> ';
              });
              _this.archives.push(archive)
            })

            // this.wizardModel = data.data.data;
            LoadProjects.reloadGeometries([data.data], function(success, results){
              let project = results[0].data;
              _this.wizardModel.informations = project.informations; 
              if (!_this.wizardModel.informations.documents) {
                _this.wizardModel.informations.documents = {
                  allowed: false,
                  plan: false,
                  other: ''
                };
              }
              if (!_this.wizardModel.informations.typeMap) {
                _this.wizardModel.informations.typeMap = {
                  waze: false,
                  map: false
                };
              }
              if (!_this.wizardModel.informations.publishReport) {
                _this.wizardModel.informations.publishReport = false;
              }
              if (!_this.wizardModel.informations.agents) {
                _this.wizardModel.informations.agents = [];
              }
              if (!_this.wizardModel.informations.passanger || ((!_this.wizardModel.informations.passanger.title) && (!_this.wizardModel.informations.passanger.title1))) {
                _this.wizardModel.informations.passanger = _this.tags.passanger;
              }
              if (!_this.wizardModel.informations.portableWater || _this.wizardModel.informations.portableWater.length==0 ) {
                _this.wizardModel.informations.portableWater = _this.tags.portableWater;
              }
              if (!_this.wizardModel.informations.trafficImpact || _this.wizardModel.informations.trafficImpact.length==0 || (_this.wizardModel.informations.trafficImpact.value && _this.wizardModel.informations.trafficImpact.value.length==11)) {
                _this.wizardModel.informations.trafficImpact = _this.tags.trafficImpact;
              }
              _this.wizardModel.entraves = project.entraves;
              _this.wizardModel.phases = project.phases;
              _this.wizardModel.projectType = project.projectType;
              
            
              let trafficImpact = JSON.parse(JSON.stringify(_this.tags.trafficImpact));
              let portableWater = JSON.parse(JSON.stringify(_this.tags.portableWater));
              _this.wizardModel.phases.forEach(function(e) {
                e.closing.forEach(function(close) {
                  if (!close.trafficImpact) {
                    close.trafficImpact=_this.tags.trafficImpact?trafficImpact:{};
                  }
                  if (!close.portableWater) {
                    close.portableWater=_this.tags.portableWater?portableWater:{};
                  }
                  if (close.publish===undefined) {
                    close.publish=true;
                  }
                });
              });
            });

          } else {
            if (data.need_auth) {
              _this.$router.push({path: '/login'});
            } else {
              _this.$notify({
                message: this.$i18n.t('serverReply.errorCreateProject'),
                timeout: 30000,
                icon: 'tim-icons icon-bell-55',
                horizontalAlign: 'center',
                verticalAlign: 'top',
                type: 'danger'
              });
            }
          }
        }).catch((e) => {
          console.log('error', e)
        });
      } else {
        let tags = Object.keys(_this.tags);
        let user = JSON.parse(window.localStorage.getItem('user'));
        let projesettings = user.projsettings;
        delete projesettings.step;
        this.checkDefaultValue();
        tags.forEach(function(element) {
          let data = projesettings[element];
          if (data) {
            _this.wizardModel.informations[element] = data;
          }
        });
        _this.wizardModel.informations.inchargeof  = user.name;
        _this.wizardModel.informations.role  = user.title;
        _this.wizardModel.informations.email  = user.email;
        _this.wizardModel.informations.agents  = [];
        _this.wizardModel.informations.publishReport  = user.publishReport;
        _this.wizardModel.informations.phone = user.phone;
        if(_this.wizardModel.informations.agents.length == 0){
          _this.wizardModel.informations.agents.push({
            name: _this.wizardModel.informations.inchargeof,
            role: _this.wizardModel.informations.role,
            phone: _this.wizardModel.informations.phone,
            email: _this.wizardModel.informations.email,
            organization: '',
            isAuthor: true
          })
        }
        
        if(_this.features.publishProjectToPublicMap.defaultValue && typeof(_this.features.publishProjectToPublicMap.defaultValue) != undefined ){
          _this.wizardModel.informations.typeMap.map  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishToCitizen.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishToCitizen.defaultValue) != undefined ){
          _this.wizardModel.informations.publish  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishProjectPhase.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishProjectPhase.defaultValue) != undefined ){
          _this.wizardModel.informations.publicMapWork  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishProjectWorkNotice.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishProjectWorkNotice.defaultValue) != undefined ){
          _this.wizardModel.informations.publishReport  = true;
        }
        if(_this.features.publishProjectToPublicMap.publishProjectToWaze.defaultValue && typeof(_this.features.publishProjectToPublicMap.publishProjectToWaze.defaultValue) != undefined ){
          _this.wizardModel.informations.typeMap.waze  = true;
        }
        if (_this.tags.passanger.show) _this.wizardModel.informations.passanger = _this.tags.passanger;
        if (_this.tags.portableWater.show) _this.wizardModel.informations.portableWater = _this.tags.portableWater;
        if (_this.tags.trafficImpact.show) _this.wizardModel.informations.trafficImpact = _this.tags.trafficImpact;
      }
    },

  addNewRow() {
    this.authorTable.isAddingRow = true;
    this.authorTable.editingRowIndex = null;
    this.authorTable.newRowData = {
      name: '',
      role: '',
      phone: '',
      email: '',
      organization: '',
      isAuthor: false
    };
  },

  editRow(index) {
    this.authorTable.editingRowIndex = index;
    this.authorTable.isAddingRow = false;
  },

  saveRow() {
    if (this.authorTable.isAddingRow) {
      this.wizardModel.informations.agents.push( { ...this.authorTable.newRowData });
      this.authorTable.isAddingRow = false;
    } else {
      this.wizardModel.informations.agents[this.authorTable.editingRowIndex] = { ...this.wizardModel.informations.agents[this.authorTable.editingRowIndex] };
      this.authorTable.editingRowIndex = null;
    }

  },
  deleteRow(index) {
    this.wizardModel.informations.agents.splice(index, 1);
    this.$notify({
        message: this.$i18n.t('quickCreate.message.handleDelete'),
        timeout: 3000,
        icon: 'tim-icons icon-bell-55',
        horizontalAlign: 'right',
        verticalAlign: 'top',
        type: 'success'
      });
    
    
  },
  onParentCheckboxChange() {
    const parentChecked = this.wizardModel.informations.typeMap.map;
    if (parentChecked) {
      this.wizardModel.informations.publish = true;
      this.wizardModel.informations.publicMapWork = true;
      this.wizardModel.informations.publishReport = true;
    }
  },

  onAdvanceDetour() {
    const detoruChecked = this.wizardModel.projectType.advanceOptions.detour;
    if (detoruChecked) {
      this.addPhase();
    }else{
      this.wizardModel.phases = [];
    }
  },

  createDates() {
      let dates=[];
        dates.push({
          dateId: uuid.v1(),
          sdate: this.wizardModel.entraves.date[0],
          edate: this.wizardModel.entraves.date[1],
          days: [1,2,3,4,5,6,7],
          closingType: ['complete'],
          closingSide: '',
          typeStreet: '',
          comment: ''
        });
      return dates;
    },
    addPhase() {
      console.log(this.wizardModel.entraves.entraves[0].name)
      let trafficImpact = JSON.parse(JSON.stringify(this.filters.trafficImpact));
      let portableWater = JSON.parse(JSON.stringify(this.filters.portableWater));
      let dates=this.createDates();
      this.wizardModel.phases.push({
        phaseId: uuid.v1(),
        closing: [{
          closingId: uuid.v1(),
          selected: this.wizardModel.entraves.entraves[0].selected,
          value: this.wizardModel.entraves.entraves[0].name,
          label : this.wizardModel.entraves.entraves[0].label,
          showError: false,
          type: 'linear',
          dates: dates,
          detours: [],
          workType1: this.wizardModel.informations.workType1,
          trafficImpact: this.filters.trafficImpact?trafficImpact:{},
          portableWater: this.filters.portableWater?portableWater:{},
          publish: true
        }]
      });
    },
    typeProject(entraves) {
      let type='ponctuel';
      entraves.forEach(function(element) {
        if (element.type=='linear') {
          type='linear';
        }
      });
      return type;
    },
    calculateConflicts(projects) {
      var _this=this;
      loadModules(["esri/geometry/geometryEngine"])
      .then(([geometryEngine]) => {
        let ProjectsConflict=[];
        let project=this.wizardModel;
        let perimeter = project.entraves.perimeter;
        let publish = project.informations.publish;
        let type = _this.typeProject(project.entraves.entraves);
        let conflictsUnits = "meters";
        let check = { entrave: true, closing: true, detour: true, date: project.entraves.date };
        project.entraves.entraves.forEach((entrave, index) => {
          let geoms, dists = [], conflictsBuffer=null;
          geoms = entrave.selected.path.map(function(object){
            dists.push(perimeter);
            return object.geometry;
          });
          let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
          conflictsBuffer = buff[0];
          let results = LoadProjects.checkConflicts('entrave', check, _this.wizardModel.uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine);
          if (results.length > 0) {
            let object = {
              uuid: _this.wizardModel.uuid,
              step: 'entrave',
              publish: publish,
              type: type,
              name: _this.wizardModel.informations.name,
              entraveIndex: (index+1),
              entraveId: entrave.entraveId,
              value: entrave.value,
              dates: project.entraves.date,
              conflicts: results
            };
            ProjectsConflict.push(object);
          }
        });
        project.phases.forEach((phase, phaseIndex) => {
          phase.closing.forEach((close, closeIndex) => {
            check.pdates = close.dates;
            let geoms, dists = [], conflictsBuffer=null;
            geoms = close.selected.path.map(function(object){
              dists.push(perimeter);
              return object.geometry;
            });
            let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
            conflictsBuffer = buff[0];
            let results = LoadProjects.checkConflicts('close', check, _this.wizardModel.uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine);
            if (results.length > 0) {
              let object = {
                uuid: _this.wizardModel.uuid,
                step: 'closing',
                publish: publish,
                type: type,
                name: _this.wizardModel.informations.name,
                phaseIndex: (phaseIndex+1),
                phaseId: phase.phaseId,
                closeIndex: (closeIndex+1),
                closingId: close.closingId,
                value: close.value,
                dates: close.dates,
                conflicts: results
              };
              ProjectsConflict.push(object);
            }
            close.detours.forEach((detour, detourIndex) => {
              check.pdates = detour.dates;
              let geoms, dists = [], conflictsBuffer=null;
              geoms = detour.selected.path.map(function(object){
                dists.push(perimeter);
                return object.geometry;
              });
              let buff = geometryEngine.geodesicBuffer(geoms, dists, conflictsUnits, true);
              conflictsBuffer = buff[0];
              let results = LoadProjects.checkConflicts('detour', check, _this.wizardModel.uuid, projects, conflictsBuffer, conflictsUnits, geometryEngine);
              if (results.length > 0) {
                let object = {
                  uuid: _this.wizardModel.uuid,
                  step: 'detour',
                  publish: publish,
                  type: type,
                  name: _this.wizardModel.informations.name,
                  phaseIndex: (phaseIndex+1),
                  phaseId: phase.phaseId,
                  closeIndex: (closeIndex+1),
                  closingId: close.closingId,
                  detourIndex: (detourIndex+1),
                  detourId: detour.detourId,
                  value: detour.value,
                  dates: detour.dates,
                  conflicts: results
                };
                ProjectsConflict.push(object);
              }
            });
          });
        });
        _this.wizardModel.conflicts=ProjectsConflict;
        // this.autoSave();
      });
    },
    conflictSearch() {
      let filters = {
        publish: true,
        nopublish: true,
        range: this.wizardModel.entraves.date || [],
        tags: {}
      };
      let _this=this;

      if(this.isViewer){
        _this.$notify({
            message: 'Back to project list',
            timeout: 1000,
            icon: 'tim-icons icon-bell-55',
            horizontalAlign: 'center',
            verticalAlign: 'top',
            type: 'danger'
          });
        this.$router.push({path: '/projects/list'});
      }else{
        LoadProjects.loadProjects(filters, function(success, data) {
          if (success) {
            let projects=data.filter((e) => { if (e.uuid!==_this.wizardModel.uuid) return e;});
            projects = projects.filter(item => !!item.data.projectType?item.data.projectType.isPublished:true) 

            if (projects && projects.length > 0) {
              _this.calculateConflicts(projects);
            }
          } else {
            _this.$notify({
              message: 'Une erreur s\'est produite lors du chargement des projets.',
              timeout: 1000,
              icon: 'tim-icons icon-bell-55',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: 'danger'
            });
          }
        });
      }
    },
    onStepValidated(validated, step, model) {
      if (validated) {
        if (step=='general') {
          let tags = Object.keys(this.tags);
          tags.forEach(function(filter) {
            if (model.informations[filter] && (Array.isArray(model.informations[filter]))) {
              model.informations[filter] = model.informations[filter].sort((a, b) => a - b);
            }
          });
          this.wizardModel.informations = model.informations;
          this.$refs.step2.validate()
        } 
        else if (step=='entraves') {
          model.perimeter = typeof(model.perimeter)==="number"?model.perimeter:parseFloat(model.perimeter);
          this.sdate = model.date[0];
          this.edate = model.date[1];
          //this.project.entraves = model.entraves;
          this.wizardModel.informations.typeProject = this.typeProject(model.entraves);
          this.wizardModel.entraves = model;
          if (this.wizardModel.informations.name=='') {
            this.wizardModel.informations.name=this.wizardModel.entraves.entraves[0].name;
          }
        }
      }
    },
    async validateAllFields(value) {
      try {
        const valid = await this.$validator.validateAll();
        if (this.wizardModel.informations.serviceInfra.length === 0 && this.wizardModel.informations.servicePublic.length === 0) {
          this.modelValidations.departmentError = true;
        }

        if (!valid || this.modelValidations.departmentError) {
          // There are validation errors
          this.$notify({
            message: this.$i18n.t('quickCreate.message.errorRelease'),
            type: "warning",
          });
          return false; // Stop execution and return false
        }

        await this.conflictSearch(); 
        this.wizardModel.projectType.isPublished = true;
        this.wizardModel.projectType.isDraft = false;

        if (this.wizardModel.projectType.advanceOptions.detour && value === 'step4') {
          await this.handleSuccess(); // Wait for handleSuccess to complete if it's async
          this.$router.push({ path: '/projects/list' }); // Proceed with routing after all async tasks
        } else if (!this.wizardModel.projectType.advanceOptions.detour && value === 'general') {
          await this.handleSuccess(); // Assume handleSuccess is not async, or its completion is not required before routing
          this.$router.push({ path: '/projects/list' }); // Proceed with routing after all async tasks
        }

        return true; // Successful execution

      } catch (error) {
        console.error("An error occurred during field validation:", error);
        return false; // In case of error, stop execution and return false
      }
    },
    validateEntraves () {
      this.wizardModel.entraves.validateEntraves=true;
      var that=this;
      this.wizardModel.entraves.entraves.forEach(function(e) {
        if (e.value==='') {
          e.showError=true;
          that.wizardModel.entraves.validateEntraves=false;
        } else {
          e.showError=false;
        }
      }); 
    },
    validateStep(value){
      if(value != "general"){
        this.$refs[value].validate()
      }
      this.onStepValidated(true,value,this.wizardModel)
      return this.validateAllFields(value);  
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    checkDefaultValue(){
        if(!!this.filters.step.defaultValue && this.wizardModel.informations.step == ''){
          this.wizardModel.informations.step = this.filters.step.defaultValue;
        }
    },
    copyFromCommunication(){
      this.wizardModel.informations.comment = this.wizardModel.informations.notes;
    },
    changeWorkCategory() {
      this.modelValidations.departmentError=false;
    },
}
};
</script>


<style>
  .card-wizard .wizard-navigation {
    margin-top: 40px !important;
  }

  .card-wizard .card-body {
    margin-top: 10px !important;
  }

  .margin-top-10 {
    margin-top: 10px;
  }

  span.el-range-separator,
  input.el-range-input {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.8) !important;
    font-size: 12px !important;
  }

  .el-picker-panel__footer {
    font-size: 12px !important;
  }

  /*
    div.el-date-editor--datetimerange:hover{
      border-color:  #2b3553 !important;
    }
    */
  input.form-control {
    margin-bottom: 0px !important;
  }

  div.el-picker-panel__footer button.el-button:first-child {
    margin-right: 10px;
  }

  div.project-form-create div.card-header {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }

  div.project-label label {
    color: #3da674 !important;
  }

  /* Information Step CSS */

  td.published-content {
    text-align: center;
  }
  td.published-content i {
    color: rgb(204, 102, 51);
  }
  td.published-content i.success {
    color: #00f2c3;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .custom-actionbar {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(43, 53, 83);
  }
  .custom-actionbar button.pell-button {
    color: #d3d7e9 !important;
  }
  .vp-editor {
    border: 1px solid rgb(43, 53, 83);
    border-radius: 0.4285rem;
    font-size: 0.75rem;
    -webkit-transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    height: 150px;
  }
  .pell-content {
    height: 100px !important;
    color: #1d253b !important;
  }
  .vp-editor:focus {
    border: 1px solid #cd51e0 !important;
  }
  .pell-button {
    width: 40px;
  }
  .select-inline {
    display: inline !important;
  }
  span.el-input__suffix,
  span.el-input__prefix {
    display: none;
  }
  .el-date-editor.el-input .el-input__inner {
    padding-left: 20px !important;
    color: #e14eca !important;
  }
  div.el-select {
    width: 100%;
  }
  i.el-tag__close.el-icon-close,
  span.el-select__tags-text {
    color: #FFF !important;
  }
  i.el-tag__close.el-icon-close:hover {
    color: #e14eca !important;
  }
  /*
  body.white-content .select-primary.el-select .el-input input {
    border-color: #8d919c !important;
  }
  */
  body.white-content .select-primary.el-select .el-input input {
    color: #e14eca;
  }
  
  body.white-content .select-primary.el-select .el-input input {
    color: #e14eca !important;
  }

  .select-primary.el-select .el-input input {
    color: rgba(255, 255, 255, 0.8);
  }
  div.el-select.select-primary.has-danger div.el-input.el-input--suffix input.el-input__inner,
  div.el-select.select-primary.has-danger div.el-input.el-input--suffix input.el-input__inner:hover {
    border: none !important;
    color: #b9afcf !important;
  }
  .select-primary.el-select .el-input:hover input {
    border-color: none !important;
  }
  .el-select .el-input.is-focus .el-input__inner{
    /*border: 1px solid rgb(43, 53, 83);*/
  }
  
  .el-select.select-primary.has-danger::after {
    content: "" !important;
  }
  .el-select.select-primary.has-danger div.el-input.el-input--suffix{
    border: 1px solid #f33620 !important;
    color: #ec250d;
    background-color: rgba(222, 222, 222, 0.1);
    border-radius: 5px;
  }
  div.info-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-bottom: 19px;
    border-bottom: 4px solid #eee;
    margin-left: 0px;
    margin-right: 0px;
  }
  div.add-agent-container {
    display: block ruby;
  }

  div.add-agent-container label {
    font-weight:bold;
  }

  .agent-add-btn {
    width: 20px;
    height: 20px;
    line-height: 18px;
    font-size: .8em;
    /*position: absolute;
    top: 15px;
    right: 15px;*/
    margin-left: 20px;
    color: #fff;
    text-align: center;
    z-index: 100;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: #fd5d93;
    cursor: pointer;
  }
  div.margin-bottom-20 {
    margin-bottom: 20px;
  }
  label.label-title {
    font-weight: bold;
  }
  div.margin-left--30 {
    margin-left: -30px;
  }
  div.padding-model{
    padding: 1.5rem;
  }
  div.form-check.waze-checkebox.disabled {
    display: block;
  }
  .card-wizard .disabled {
    display: block;
  }
  .attach-button{
    margin:0 !important;
  }
  tbody.archive-table-body tr td.btn-container {
    visibility: hidden;
    opacity: 0;
    transition:visibility 0.3s linear,opacity 0.3s linear;
  }
  tbody.archive-table-body tr:hover td.btn-container {
    position: relative;
    visibility: visible;
    opacity: 1;
  }
  tbody.archive-table-body tr td.btn-container div {
    width: 200px;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  div.card.archives-card div.card-body .progress-container .progress .progress-bar .progress-value {
    /*top: -18px !important;*/
  }
  div.card.archives-card tbody.archive-table-body div.progress-container.progress-primary  {
    margin-top: 0px !important;
  }
  div.card.archives-card tbody.archive-table-body tr td p{
    margin-bottom: 2px;
  }
  div.card.archives-card tbody.archive-table-body tr td p.title {
    padding-top: 0px !important;
  }

  .project-label-cost input::-webkit-outer-spin-button,
  .project-label-cost input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  .project-label-cost input[type=number] {
      -moz-appearance:textfield; /* Firefox */
  }

  .btn-no-border{
    background: none;
    border: none;
  }

  .collapse-content{
    margin-left: 1rem;
    visibility: hidden;
    height: 0;
    transition: visibility 0s, opacity 0.5s ease-out;
    opacity: 0;
  }

  .btn-no-border.visible{
    transform: rotate(180deg);
    
  }

  .collapse-content.visible{
    visibility: visible;
    opacity: 1;
    height: auto;
  }

  .upload-box{
    border: 1px solid;
    border-radius: 6px;
    height: 140px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  body.white-content .upload-box span,body.white-content .upload-box i{
    color: inherit
  }

  body.white-content .el-table th.el-table__cell{
    background-color: #fff;
  }

  .el-table th.el-table__cell{
    background-color:transparent;
  }
  .upload-box span,.upload-box i{
    color: hsla(0,0%,100%,.6);
  }



  .checkbox-advance .upload-box{
    background: #F5F6FA;
    /* color: hsla(0,0%,100%,.6); */
  }

  .upload-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width : 100%;
  }

  .upload-btn i{
    font-size: 32px;
  }

  .upload-btn .upload-content{
    font-size : 18px;
    margin-left: 28px;
  }
  .upload-content{
    display: grid;
  }

  .upload-content > :first-child{
    text-decoration: underline;
  }

  .checkbox-advance{
    /* margin : 0; */
    width: 100%;
    height: 80px;
    border: 0;
    border-radius : 6px;
    padding: 16px;
    border: 1px solid;
  }

  .checkbox-advance.form-check .form-check-sign:after,.checkbox-advance.form-check .form-check-sign::before{
    top: 3px;
  }
  body.white-content .checkbox-advance{
    background: #f5f6fa;

  }

  .checkbox-advance .form-check-sign:before{
    border-radius: 16px;
  }

  .checkbox-advance.visible{
    background: rgba(206, 81, 223, 0.19);
    border : 1px solid #CE51DF;
  }
  .checkbox-advance > *{
    width: 100%;
  }

  .checkbox-advance-title{
    margin: 0;
  }

  

  .collapse-project .colapse-title{
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;

  }
  .fieldset-group{
    display: block;
      margin-inline-start: 2px;
      margin-inline-end: 2px;
      padding-block-start: 0.35em;
      padding-inline-start: 0.75em;
      padding-inline-end: 0.75em;
      padding-block-end: 0.625em;
      min-inline-size: min-content;
      border-width: 2px;
      border-style: groove;
      border-color: rgb(192, 192, 192);
      border-image: initial;
  }

  .fieldset-title{
    color: hsla(0,0%,100%,.6);
  }

  body.white-content .fieldset-title{
    color: #344675;
  }
  

  .white-content .collapse-project>.collapse-quick-create>.card-header{
    background: #F5F6FA !important;
  }

  .collapse-project>.collapse-quick-create>.card-header{
    padding: 0.5rem 0.75rem !important;
    border-radius: 6px;
  }

  .collapse-project>.collapse-quick-create>.card-header> a{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
</style>