<template>
  <div class="row margin-top-10" style="margin-bottom: 30px;">
    <div class="col-sm-4 col-md-4 col-lg-4">
      <div class="col-sm-12 col-md-12 col-lg-12 detour-date-content">
        <label> {{ $t('projectForm.labelClosingPeriode')}}</label>
        <base-input>
          <el-date-picker
            v-model="date"
            type="datetimerange"
            value-format="timestamp"
            range-separator="-"
            format="yyyy-MM-dd HH:mm"
            :disabled="statusOfUser"
            @change="$emit('updateClosingDate', [item.dateId, $event])"
            :picker-options="{disabledDate: disableDates}"
            :clearable="false"
            v-bind:start-placeholder="$t('projectForm.labelProjectStart')"
            v-bind:end-placeholder="$t('projectForm.labelProjectEnd')">
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 closing-days-content">
        <span class="badge" v-bind:class="daysClass(1)" @click="activeLink(1)">{{ $t('projectForm.monday') }}</span>
        <span class="badge" v-bind:class="daysClass(2)" @click="activeLink(2)">{{ $t('projectForm.tuesday') }}</span>
        <span class="badge" v-bind:class="daysClass(3)" @click="activeLink(3)">{{ $t('projectForm.wednesday') }}</span>
        <span class="badge" v-bind:class="daysClass(4)" @click="activeLink(4)">{{ $t('projectForm.thursday') }}</span>
        <span class="badge" v-bind:class="daysClass(5)" @click="activeLink(5)">{{ $t('projectForm.friday') }}</span>
        <span class="badge" v-bind:class="daysClass(6)" @click="activeLink(6)">{{ $t('projectForm.saturday')}}</span>
        <span class="badge" v-bind:class="daysClass(7)" @click="activeLink(7)">{{ $t('projectForm.sunday') }}</span>
      </div>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2">
      <label>{{ $t('projectForm.labelClosingType') }}</label>
      <el-select multiple
        class="select-primary"
        v-bind:placeholder="$t('projectForm.placeholderClosingType')"
        name="closingType"
        :disabled="statusOfUser"
        v-model="item.closingType">
        <el-option
          v-for="option in filters.typeOfClosure.value"
          class="select-primary"
          :value="option.value"
          :label="option.label"
          :key="option.label">
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-2 col-md-2 col-lg-2">
      <label>{{ $t('projectForm.labelClosingSide') }}</label>
      <el-select multiple
        class="select-primary"
        :disabled="statusOfUser"
        v-bind:placeholder="$t('projectForm.placeholderClosingSide')"
        name="closingSide"
        v-model="item.closingSide">
        <el-option
          v-for="option in closingSides"
          class="select-primary"
          :value="option.value"
          :label="option.label"
          :key="option.label">
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-3 col-md-3 col-lg-3">
      <label>{{ $t('projectForm.labelTypeStreet') }}</label>
      <el-select multiple
        class="select-primary"
        :disabled="statusOfUser"
        v-bind:placeholder="$t('projectForm.placeholderTypeOfStreet')"
        name="typeStreet"
        v-model="item.typeStreet">
        <el-option
          v-for="option in typeOfStreets"
          class="select-primary"
          :value="option.value"
          :label="option.label"
          :key="option.label">
        </el-option>
      </el-select>
    </div>
    <div class="col-sm-1 col-md-1 col-lg-1">
      <div class="closing-date-remove-btn btn-danger" v-if="!statusOfUser" v-on:click="$emit('removeClosingDate', [index, item.dateId])"><span><i class="tim-icons icon-simple-remove"></i></span></div>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-12 richtext-wrapper" >
      <label>{{ $t('projectForm.labelClosingComment') }}</label>
      <span v-if="statusOfUser"  v-html="item.comment"></span>
      <!-- <vue-pell-editor 
            v-else
            v-model="item.comment"
            :actions="editorOptions"
            :placeholder="editorPlaceholder"
            :style-with-css="false"
            :classes="editorClasses"
            default-paragraph-separator="p"/> -->
      <div ref="editor" v-bind:class="'quill-editor-closure_'+indexPhase+'_'+index"></div>
    </div>
  </div>
</template>
<script>
import { TimeSelect, DatePicker, Select, Option } from 'element-ui';
import Vue from 'vue';
import VuePellEditor from 'vue-pell-editor';
Vue.use(VuePellEditor);
import quillEditorToolbar from './../shared/quillEditorToolbar';

export default {
  name: 'closing-date-component',
   components: {
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Option.name]: Option,
    [Select.name]: Select,
  },
  props: ['item', 'index', 'pdate','statusOfUser', 'filters', 'indexPhase'],
  computed: {
  },
  watch: {
    date(val) {
      this.item.sdate = val[0];
      this.item.edate = val[1];
    },
    closingType(val) {
      this.item.closingType=val.value;
    }
  },
  mounted () {
    let _this = this;
    _this.quillEditorClosure = new Quill(this.$refs.editor, {
      modules: {
        toolbar: quillEditorToolbar
      },
      theme: 'snow'
    });
    _this.quillEditorClosure.on('text-change', function() {
      _this.item.comment = _this.quillEditorClosure.root.innerHTML;
    });
    _this.quillEditorClosure.clipboard.dangerouslyPasteHTML(_this.item.comment);
  },
  data () {
    return {
      date: [this.item.sdate, this.item.edate],
      //pdate: [this.pdate, this.pdate],
      pdate1: [this.pdate, this.pdate],
      closingType: this.item.closingType,
      comment: '',
      closingTypes:[
        { value: 'complete', label: this.$i18n.t('projectForm.selectWayFull') },
        { value: 'right', label: this.$i18n.t('projectForm.selectWayRight') },
        { value: 'left', label: this.$i18n.t('projectForm.selectWayLeft') },
        { value: 'alternate', label: this.$i18n.t('projectForm.selectWayAltern') },
        { value: 'curve', label: this.$i18n.t('projectForm.selectCurve') },
        { value: 'centerLane', label: this.$i18n.t('projectForm.selectCenterLane') },
        { value: 'sidewalk', label: this.$i18n.t('projectForm.selectSidewalk') },
        { value: 'partial', label: this.$i18n.t('projectForm.selectPartial') }
      ],
      closingSides:[
        { value: 'north', label: this.$i18n.t('projectForm.selectNorth') },
        { value: 'south', label: this.$i18n.t('projectForm.selectSouth') },
        { value: 'est', label: this.$i18n.t('projectForm.selectEst') },
        { value: 'west', label: this.$i18n.t('projectForm.selectWest') }
      ],
      typeOfStreets:[
        { value: 'artirial', label: this.$i18n.t('projectForm.selectArtirial') },
        { value: 'residential', label: this.$i18n.t('projectForm.selectResidential') },
        { value: 'collecteur', label: this.$i18n.t('projectForm.selectCollecteur') },
        { value: 'local', label: this.$i18n.t('projectForm.selectLocal') },
        { value: 'commercial', label: this.$i18n.t('projectForm.selectCommercial') },
        { value: 'mix', label: this.$i18n.t('projectForm.selectMix') },
        { value: 'hospital', label: this.$i18n.t('projectForm.selectHospital') },
        { value: 'school', label: this.$i18n.t('projectForm.selectSchool') }
      ],
      editorOptions: [
        'bold',
        'underline', {
            name: 'italic',
            result: () => window.pell.exec('italic')
        }, 
        'heading1',
        'heading2',
        'paragraph',
        'strikethrough',
        'olist',
        'ulist',
        'code',
        'line'
      ],
      editorPlaceholder: this.$i18n.t('projectForm.placeholderComment'),
      editorClasses: {
          actionbar: 'pell-actionbar custom-actionbar',
          button: 'pell-button',
          content: 'pell-content',
          selected: 'pell-button-selected'
      },
      quillEditorClosure: null
    };
  },
  methods: {
    disableDates(date) {
      return ((this.pdate1[0] > date) || (this.pdate1[1] < date));
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit('on-validated', res, this.model);
        return res;
      });
    },
    daysClass(day) {
      if (this.item.days.includes(parseInt(day,10))) {
        return 'badge-info';
      } else {
        return 'badge-disable';
      }
    },
    activeLink(day) {
      if (this.item.days.includes(parseInt(day,10))) {
        this.item.days = this.item.days.filter(function(item) { 
          return item !== day;
        });
      } else {
        this.item.days.push(day);
      }
      this.item.days.sort(function(a, b){return a - b});
    },
    onItemChange({ html }) {
        this.item.comment = html;
    },
  }
}
</script>
<style>
  div.closing-dates-container div.row.margin-top-10:only-child div.closing-date-remove-btn.btn-danger {
    display: none;
  }
  .el-date-editor.el-range-editor.el-input__inner.el-date-editor--datetimerange {
    padding-top: 3px !important;
  }
</style>